import {
  query,
  orderBy,
  startAfter,
  startAt,
  limit,
  collection,
  doc,
  where,
  setDoc,
  updateDoc,
  deleteDoc,
  getDoc,
  getDocs,
  onSnapshot,
  type Unsubscribe,
  serverTimestamp,
  increment,
  DocumentReference,
  Query,
  documentId,
  Timestamp,
} from "firebase/firestore";
import type {
  ITalkRoomRepository,
  talkRoomObserverType,
  talkObserverType,
  userObserverType,
  userGoodObserverType,
} from "~/interfaces/ITalkRoomRepository";
import { TalkRoom } from "~/entities/TalkRoom";
import { Talk, TalkConverter } from "~/entities/Talk";
import { TalkRoomUser, TalkRoomUserConverter } from "~/entities/TalkRoomUser";
import { TalkLastRead, TalkLastReadConverter } from "~/entities/TalkLastRead";
import type { TalkGoodUserResponse } from "~/entities/TalkGoodUserResponse";
import { TALK_ROOM_TOTAL_MAX } from "~/consts/TalkConfig";

/* データ構成

トーク
  /talks/{plan_id}/talkRooms/{talk_room_id}/talks/{talk_id:Talk}

ユーザーデータ
  /talks/{plan_id}/users/{user_id:TalkRoomUser}

いいね管理データ
  /talks/{plan_id}/talkRooms/{talk_room_id}/talks/{talk_id}/favoriteUsers/{user_id}

既読管理データ
  /users/{user_id}/talkRooms/{talk_room_id:lastReadTalkId}

ユーザー別いいねデータ
  /users/{user_id}/talkRooms/{talk_room_id}/likes/{talk_id}
*/

const baseCollectionName = "talks";
const talkRoomCollectionName = "talkRooms";
const talkCollectionName = "talks";
const userCollectionName = "users";
const favoriteCollectionName = "favoriteUsers";
const userGoodCollectionName = "likes";

export class TalkRoomRepository implements ITalkRoomRepository {
  // トークルーム一覧の監視用
  talkRoomsUnsubscribe: Unsubscribe | undefined = undefined;

  // トークルームのトークの監視用
  talksAddUnsubscribe: Unsubscribe | undefined = undefined;
  talksChangeUnsubscribe: Unsubscribe | undefined = undefined;

  // トークルームのユーザー監視用
  usersAddUnsubscribe: Unsubscribe | undefined = undefined;
  usersChangeUnsubscribe: Unsubscribe | undefined = undefined;

  // トークルームのいいね監視用
  userGoodsUnsubscribe: Unsubscribe | undefined = undefined;

  getTalkRoomCollection(planId: string) {
    const { $firestore } = useNuxtApp();
    return collection($firestore, baseCollectionName, String(planId), talkRoomCollectionName);
  }

  startTalkRoomsObserve(planId: string, addCallBack: talkRoomObserverType, changeCallBack: talkRoomObserverType): void {
    this.stopTalkRoomsObserve();
    const collection = this.getTalkRoomCollection(planId);
    const q = query(collection);
    this.talkRoomsUnsubscribe = onSnapshot(q, snapshot => {
      snapshot.docChanges().forEach(change => {
        // 変更時
        if (change.type === "modified") {
          const data = change.doc.data();
          if (data?.LatesteTalkID) {
            changeCallBack({ id: change.doc.id, latesteTalkID: data.LatesteTalkID });
          }
        }
        // 追加時
        if (change.type === "added") {
          const data = change.doc.data();
          if (data?.LatesteTalkID) {
            addCallBack({ id: change.doc.id, latesteTalkID: data.LatesteTalkID });
          }
        }
      });
    });
  }

  stopTalkRoomsObserve() {
    if (this.talkRoomsUnsubscribe) {
      this.talkRoomsUnsubscribe();
      this.talkRoomsUnsubscribe = undefined;
    }
  }

  // トーク一覧のcollectionを取得
  getTalksCollection(planId: string, talkRoomId: string) {
    const { $firestore } = useNuxtApp();
    return collection(
      $firestore,
      baseCollectionName,
      String(planId),
      talkRoomCollectionName,
      String(talkRoomId),
      talkCollectionName,
    ).withConverter(TalkConverter);
  }

  // トークのdocumentを取得
  getTalkDocument(planId: string, talkRoomId: string, talkId: string) {
    const { $firestore } = useNuxtApp();
    return doc(
      $firestore,
      baseCollectionName,
      String(planId),
      talkRoomCollectionName,
      String(talkRoomId),
      talkCollectionName,
      String(talkId),
    ).withConverter(TalkConverter);
  }

  // ユーザー一覧のcollectionを取得
  getUsersCollection(planId: string) {
    const { $firestore } = useNuxtApp();
    return collection($firestore, baseCollectionName, String(planId), userCollectionName).withConverter(
      TalkRoomUserConverter,
    );
  }

  // ユーザー別いいね一覧のcollectionを取得
  getUserGoodCollectionQuery(userId: string, talkRoomId: string) {
    const { $firestore } = useNuxtApp();
    return collection(
      $firestore,
      userCollectionName,
      String(userId),
      talkRoomCollectionName,
      String(talkRoomId),
      userGoodCollectionName,
    );
  }

  // トーク監視
  startTalksObserve(
    planId: string,
    talkRoomId: string,
    addCallback: talkObserverType | undefined,
    addAfterTalkId: string | undefined,
    changeCallback: talkObserverType,
    changeFromTalkId: string | undefined,
    changeToTalkId: string | undefined,
    initDone: Function,
  ): void {
    this.stopTalksObserve();
    const collection = this.getTalksCollection(planId, talkRoomId);

    if (addCallback) {
      // 追加監視の作成
      // 追加は今取得しているIDより大きいものを監視する
      const addQuery = addAfterTalkId
        ? query(collection, where("TalkId", ">", Number(addAfterTalkId)))
        : query(collection);
      this.talksAddUnsubscribe = onSnapshot(addQuery, snapshot => {
        snapshot.docChanges().forEach(change => {
          // 追加時
          if (change.type === "added") {
            addCallback(change.doc.data());
          }
        });
      });
    }

    // 変更監視の作成
    // 変更監視は今取得している範囲のデータを監視する
    const changeQuery =
      changeFromTalkId && changeToTalkId
        ? query(
            collection,
            where("TalkId", ">=", Number(changeFromTalkId)),
            where("TalkId", "<=", Number(changeToTalkId)),
          )
        : query(collection);
    this.talksChangeUnsubscribe = onSnapshot(changeQuery, snapshot => {
      snapshot.docChanges().forEach(change => {
        // 変更時 (論理削除含む)
        if (change.type === "modified") {
          changeCallback(change.doc.data());
        }
      });
    });
    initDone();
  }

  // トーク監視停止
  stopTalksObserve() {
    if (this.talksAddUnsubscribe) {
      this.talksAddUnsubscribe();
      this.talksAddUnsubscribe = undefined;
    }
    if (this.talksChangeUnsubscribe) {
      this.talksChangeUnsubscribe();
      this.talksChangeUnsubscribe = undefined;
    }
  }

  // Firestoreから document 単体取得 (キャッシュ有効時はキャッシュから取得)
  async getDoc(docRef: DocumentReference) {
    return featureFirestoreCache() ? await getDocFromCacheOrServer(docRef) : await getDoc(docRef);
  }

  // Firestoreから document リスト取得 (キャッシュ有効時はキャッシュから取得)
  async getDocs(query: Query, useCacheIfAvailable: boolean) {
    return useCacheIfAvailable && featureFirestoreCache()
      ? await getDocsFromCacheOrServer(query)
      : await getDocs(query);
  }

  // トークIDを基準にしてトーク一覧を取得する
  //
  // 想定する使い方は下記の通り。
  // - 1. 初回読み込み: talkId=undefined, isBefore=true, includeSelf=false
  // - 2. 上無限スクロール: talkId=先頭トークID, isBefore=true, includeSelf=false
  // - 3. 下無限スクロール: talkId=末尾トークID, isBefore=false, includeSelf=false
  // - 4. ダイレクトジャンプ: talkId=対象トークID, isBefore=true, includeSelf=false + talkId=対象トークID, isBefore=false, includeSelf=true (2回呼んで連結する。その後は上下無限スクロールできる)
  //
  // 通常時はトークルームオープン時に1で初回読み込みを行い、その後は上無限スクロール時に2を行う
  // 返信元や検索結果のトークへダイレクトジャンプする際は4で対象トーク＋前後のトーク一覧へ差し替え、その後は上下の無限スクロール時に2,3を行う
  async getTalks(
    planId: string,
    talkRoomId: string,
    talkId: string | undefined,
    isBefore: boolean,
    includeSelf: boolean,
    pageSize: number,
    useCacheIfAvailable: boolean,
  ): Promise<Talk[]> {
    // トークID未指定時は、includeSelf指定不可
    if (!talkId && includeSelf) {
      throw new Error("TalkRoomRepository.getTalks: talkId is required when includeSelf is true");
    }

    // 返すデータに論理削除が多く含まれるとスクロール動作が正常に動かない
    // よって論理削除を含まずに10個以上返すように繰り返す
    let resultTalks: Talk[] = [];
    for (let i = 0; ; i++) {
      const talks = await this.getTalksOnce(
        planId,
        talkRoomId,
        talkId,
        isBefore,
        includeSelf,
        pageSize,
        useCacheIfAvailable,
      );
      const activeTalks = talks.filter(talk => !talk.deleteType);
      resultTalks = isBefore ? talks.concat(resultTalks) : resultTalks.concat(talks);
      const resultActiveTalks = resultTalks.filter(talk => !talk.deleteType);
      // 論理削除されてないトークが 10 個以上蓄積するか、
      // 論理削除がひとつも含まれなくなったら終了
      if (resultActiveTalks.length >= 10 || talks.length === activeTalks.length) {
        break;
      } else if (i === 5) {
        // 無限ループを避けるため、5回で終わらなかったらエラーログを出して終了
        sentryErrorLog(`getTalks: too may talkRoomId=${talkRoomId}`);
        break;
      }
      // 基準トークIDを更新して繰り返す
      talkId = isBefore ? talks.at(0)!.talkId : talks.at(-1)!.talkId;
    }
    return resultTalks;
  }

  async getTalksOnce(
    planId: string,
    talkRoomId: string,
    talkId: string | undefined,
    isBefore: boolean,
    includeSelf: boolean,
    pageSize: number,
    useCacheIfAvailable: boolean,
  ): Promise<Talk[]> {
    // 特定トークIDを基準に取得
    const collection = this.getTalksCollection(planId, talkRoomId);
    const snapshot = talkId
      ? await this.getDocs(
          query(
            collection,
            orderBy("TalkId", isBefore ? "desc" : "asc"),
            includeSelf ? startAt(Number(talkId)) : startAfter(Number(talkId)),
            limit(pageSize),
          ),
          useCacheIfAvailable,
        )
      : await this.getDocs(
          query(collection, orderBy("TalkId", isBefore ? "desc" : "asc"), limit(pageSize)),
          useCacheIfAvailable,
        );

    // トークID昇順リストで返す
    const list: Talk[] = [];
    snapshot.forEach(doc => {
      const data = doc.data();
      list.push(data);
    });
    return isBefore ? list.reverse() : list;
  }

  // トーク取得
  async getTalk(planId: string, talkRoomId: string, talkId: string): Promise<Talk | undefined> {
    const doc = this.getTalkDocument(planId, talkRoomId, talkId);
    const snap = await this.getDoc(doc);
    if (snap.exists()) {
      return snap.data();
    }
    return undefined;
  }

  // ユーザー監視
  startUsersObserve(planId: string, addCallback: userObserverType, changeCallback: userObserverType): void {
    this.stopUsersObserve();
    const collection = this.getUsersCollection(planId);

    // 監視の作成
    this.usersChangeUnsubscribe = onSnapshot(query(collection, where("UpdateDate", ">", Timestamp.now())), snapshot => {
      snapshot.docChanges().forEach(change => {
        // 追加時
        if (change.type === "added") {
          addCallback(change.doc.data());
        }
        // 変更時
        if (change.type === "modified") {
          changeCallback(change.doc.data());
        }
      });
    });
  }

  // ユーザー監視停止
  stopUsersObserve() {
    if (this.usersAddUnsubscribe) {
      this.usersAddUnsubscribe();
      this.usersAddUnsubscribe = undefined;
    }
    if (this.usersChangeUnsubscribe) {
      this.usersChangeUnsubscribe();
      this.usersChangeUnsubscribe = undefined;
    }
  }

  // ユーザー監視
  startUserGoodsObserve(userId: string, talkRoomId: string, callBack: userGoodObserverType): void {
    this.stopUserGoodsObserve();
    const collection = this.getUserGoodCollectionQuery(userId, talkRoomId);

    // 監視の作成
    this.userGoodsUnsubscribe = onSnapshot(query(collection), snapshot => {
      snapshot.docChanges().forEach(change => {
        callBack(change.type, change.doc.id);
      });
    });
  }

  // ユーザー監視停止
  stopUserGoodsObserve() {
    if (this.userGoodsUnsubscribe) {
      this.userGoodsUnsubscribe();
      this.userGoodsUnsubscribe = undefined;
    }
  }

  // ユーザー一覧取得 (ユーザーIDリストで引当)
  async getUsers(planId: string, userIds: string[]): Promise<TalkRoomUser[]> {
    // userIds をユニークにさせる
    const uniqUserIds = Array.from(new Set(userIds));

    // firestore 仕様で where in で指定可能なのは 30 個までなので分割実行
    let list: TalkRoomUser[] = [];
    for (let i = 0; i < uniqUserIds.length; i += 30) {
      const slicedUserIds = uniqUserIds.slice(i, i + 30);
      const querySnapshot = await this.getDocs(
        query(this.getUsersCollection(planId), where(documentId(), "in", slicedUserIds)),
        true,
      );
      list = list.concat(querySnapshot.docs.map(doc => doc.data()));
    }
    return list;
  }

  // 既読管理用docの取得
  getLastReadDocQuery(userId: string, talkRoomId: string) {
    const { $firestore } = useNuxtApp();
    return doc(
      $firestore,
      userCollectionName,
      String(userId),
      talkRoomCollectionName,
      String(talkRoomId),
    ).withConverter(TalkLastReadConverter);
  }

  // 既読設定
  async setLastRead(userId: string, talkRoomId: string, talkId: string): Promise<boolean> {
    try {
      const talkIdNumber = Number(talkId);
      if (!talkIdNumber) {
        throw new Error("Error set lastReadTalkId: invalid talkId");
      }
      const doc = this.getLastReadDocQuery(userId, talkRoomId);
      const snap = await getDoc(doc);
      if (snap.exists()) {
        if (talkIdNumber <= snap.data().lastReadTalkId) {
          // サーバー上にある既読talkId以下の場合は更新しない
          return false;
        }
      }
      // 更新
      await setDoc(doc, new TalkLastRead(talkRoomId, talkIdNumber));
      return true;
    } catch (e) {
      throw new Error("Error set lastReadTalkId: " + e);
    }
  }

  // いいね管理用collectionの取得
  getGoodCollectionQuery(planId: string, talkRoomId: string, talkId: string) {
    const { $firestore } = useNuxtApp();
    return collection(
      $firestore,
      baseCollectionName,
      String(planId),
      talkRoomCollectionName,
      String(talkRoomId),
      talkCollectionName,
      String(talkId),
      favoriteCollectionName,
    );
  }

  // いいね管理用docの取得
  getGoodDocQuery(planId: string, talkRoomId: string, talkId: string, userId: string) {
    const { $firestore } = useNuxtApp();
    return doc(
      $firestore,
      baseCollectionName,
      String(planId),
      talkRoomCollectionName,
      String(talkRoomId),
      talkCollectionName,
      String(talkId),
      favoriteCollectionName,
      String(userId),
    );
  }

  // ユーザー別いいね一覧のcollectionを取得
  getUserGoodDocQuery(userId: string, talkRoomId: string, talkId: string) {
    const { $firestore } = useNuxtApp();
    return doc(
      $firestore,
      userCollectionName,
      String(userId),
      talkRoomCollectionName,
      String(talkRoomId),
      userGoodCollectionName,
      String(talkId),
    );
  }

  // いいねを送る
  async sendGood(planId: string, talkRoomId: string, talkId: string, userId: string): Promise<void> {
    try {
      const userGoodDoc = this.getUserGoodDocQuery(userId, talkRoomId, talkId);
      const userGoodSnap = await getDoc(userGoodDoc);

      const goodDoc = this.getGoodDocQuery(planId, talkRoomId, talkId, userId);
      const goodSnap = await getDoc(goodDoc);

      if (!goodSnap.exists() && !userGoodSnap.exists()) {
        // ユーザー別いいねデータ追加
        await setDoc(userGoodDoc, { createdAt: serverTimestamp() });

        // いいねデータ追加
        await setDoc(goodDoc, { createdAt: serverTimestamp() });

        const talkDoc = this.getTalkDocument(planId, talkRoomId, talkId);
        await updateDoc(talkDoc, { GoodCount: increment(1) });
      }
    } catch (e) {
      throw new Error("Error sendGood: " + e);
    }
  }

  // いいねを削除
  async deleteGood(planId: string, talkRoomId: string, talkId: string, userId: string): Promise<void> {
    try {
      const userGoodDoc = this.getUserGoodDocQuery(userId, talkRoomId, talkId);
      const userGoodSnap = await getDoc(userGoodDoc);

      const goodDoc = this.getGoodDocQuery(planId, talkRoomId, talkId, userId);
      const goodSnap = await getDoc(goodDoc);

      if (goodSnap.exists() && userGoodSnap.exists()) {
        // ユーザー別いいねデータ削除
        await deleteDoc(userGoodDoc);

        // いいねデータ削除
        await deleteDoc(goodDoc);

        const talkDoc = this.getTalkDocument(planId, talkRoomId, talkId);
        await updateDoc(talkDoc, { GoodCount: increment(-1) });
      }
    } catch (e) {
      throw new Error("Error deleteGood: " + e);
    }
  }

  // いいね変更通知用doc
  getGoodNotificationDocQuery(planId: string, talkRoomId: string, talkId: string) {
    const { $firestore } = useNuxtApp();
    return doc(
      $firestore,
      baseCollectionName,
      String(planId),
      talkRoomCollectionName,
      String(talkRoomId),
      talkCollectionName,
      String(talkId),
    );
  }

  // いいね変更通知用doc
  async sendGoodNotification(planId: string, talkRoomId: string, talkId: string): Promise<void> {
    try {
      const doc = this.getGoodNotificationDocQuery(planId, talkRoomId, talkId);
      const snap = await getDoc(doc);
      if (snap.exists()) {
        // いいね更新時間を更新する
        await updateDoc(doc, { favoriteUpdatedAt: serverTimestamp() });
        // await setDoc(doc, {favoriteUpdatedAt: serverTimestamp()}, { merge: true });
      }
    } catch (e) {
      throw new Error("Error sendGood: " + e);
    }
  }

  // api

  async getTalkRooms(planId: string, page = 1, pageSize = TALK_ROOM_TOTAL_MAX): Promise<TalkRoom[]> {
    const paramsObj: { [name: string]: string } = {
      page: page.toString(),
      page_size: pageSize.toString(),
    };
    const searchParams = new URLSearchParams(paramsObj);
    const res = await GetRequest<{ statusCode: number; talkRooms: [] }>(
      `/talk/room/${planId}/?` + searchParams.toString(),
    );
    if (!res || res.statusCode !== 200) {
      throw new Error(`TalkRoomRepository.getTalkRooms API Error: ${JSON.stringify(res)}`);
    }
    return res.talkRooms;
  }

  async searchTalksFromApi(talkRoomId: string, searchWord: string): Promise<Talk[]> {
    const paramsObj: { [name: string]: string } = {
      search_word: searchWord,
      sort: "desc",
      page_size: "100",
    };
    const searchParams = new URLSearchParams(paramsObj);
    const res = await GetRequest<{ statusCode: number; talks: [] }>(`/talk/${talkRoomId}/?` + searchParams.toString());
    if (!res || res.statusCode !== 200) {
      throw new Error(`TalkRoomRepository.searchTalksFromApi API Error: ${JSON.stringify(res)}`);
    }
    return res.talks.reverse();
  }

  async getGoodUsersFromApi(talkId: string, page: number): Promise<TalkRoomUser[]> {
    const paramsObj: { [name: string]: string } = {
      page: page.toString(),
      page_size: "100",
    };
    const searchParams = new URLSearchParams(paramsObj);
    const res = await GetRequest<TalkGoodUserResponse>(`/talk/goodUsers/${talkId}?` + searchParams.toString());
    if (!res || res.statusCode !== 200) {
      throw new Error(`TalkRoomRepository.getGoodUsersFromApi API Error: ${JSON.stringify(res)}`);
    }
    return res.talkGoodUsers;
  }

  async getTalkFromApi(talkRoomId: string, talkId: string): Promise<Talk | undefined> {
    const paramsObj: { [name: string]: string } = {
      talk_id: String(talkId),
    };
    const searchParams = new URLSearchParams(paramsObj);
    const res = await GetRequest<{ statusCode: number; talks: Array<Talk> }>(
      `/talk/${talkRoomId}/?` + searchParams.toString(),
    );
    if (!res || res.statusCode !== 200) {
      throw new Error(`TalkRoomRepository.getTalkFromApi API Error: ${JSON.stringify(res)}`);
    }
    return res.talks?.length ? res.talks[0] : undefined;
  }

  async sendTalk(planId: string, talkRoomId: string, message: string, replyTalkId: string | undefined): Promise<void> {
    const res = await PostRequest<{ statusCode: number }>(
      `/talk/post/text/${talkRoomId}`,
      JSON.stringify({
        contents: message,
        reply_talk_id: replyTalkId,
      }),
    );
    if (!res || res.statusCode !== 200) {
      throw new Error(`TalkRoomRepository.sendTalk API Error: ${JSON.stringify(res)}`);
    }
  }

  async sendFile(talkRoomId: string, file: File, replyTalkId: string | undefined): Promise<void> {
    const uploadFile = isImageFile(file) ? await convertTalkImage(file) : file;
    // 署名付きS3アップロードURLを取得する
    const mimeType = uploadFile.type;
    const presignedRes = await GetRequest<{
      statusCode: number;
      presignedUrl: string;
      fileKey: string;
    }>(`/talk/post/file/presigned_url?mime_type=${mimeType}`);
    if (!presignedRes || presignedRes.statusCode !== 200) {
      throw new Error("Failed get presigned_url");
    }

    // S3 にアップロード
    const s3Res = await PutS3Request(presignedRes.presignedUrl, uploadFile);
    if (!s3Res || s3Res.statusCode !== 200) {
      throw new Error("Failed to put s3");
    }

    if (isImageFile(uploadFile)) {
      // 画像の場合はサムネイルを作成してアップロード
      const base64File = await convertTalkThumbnail(uploadFile);

      const res = await PostRequest<{ statusCode: number }>(
        `/talk/post/image/${talkRoomId}`,
        JSON.stringify({
          file_key: presignedRes.fileKey,
          thumbnail_image: base64File,
          reply_talk_id: replyTalkId,
        }),
      );
      if (!res || res.statusCode !== 200) {
        throw new Error("Failed to post talk file");
      }
    } else if (isPdfFile(uploadFile)) {
      // pdfはファイルネームを付与してアップロード
      const res = await PostRequest<{ statusCode: number }>(
        `/talk/post/pdf/${talkRoomId}`,
        JSON.stringify({
          file_key: presignedRes.fileKey,
          file_name: uploadFile.name,
          reply_talk_id: replyTalkId,
        }),
      );
      if (!res || res.statusCode !== 200) {
        throw new Error("Failed to post talk file");
      }
    } else {
      // その他のファイルはエラー
      throw new Error("Invalid file type");
    }
  }

  async deleteTalk(talkId: string): Promise<void> {
    const res = await DeleteRequest<{ statusCode: number }>(`/talk/post/${talkId}`);
    if (!res || res.statusCode !== 200) {
      throw new Error(`Failed delete talk: response=${JSON.stringify(res)}`);
    }
  }
}
