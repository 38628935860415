import {
  getDocFromServer,
  getDocFromCache,
  getDocsFromServer,
  getDocsFromCache,
  DocumentReference,
  DocumentSnapshot,
  Query,
  QuerySnapshot,
} from "firebase/firestore";

export const getDocsFromCacheOrServer = async (query: Query): Promise<QuerySnapshot<any>> => {
  let snapshot: QuerySnapshot<any> | undefined;
  try {
    // キャッシュから取得
    snapshot = await getDocsFromCache(query);
  } catch (e) {
    consoleLog("[fs debug] getDocsFromCache error", e);
  }
  if (!snapshot || snapshot.empty) {
    // キャッシュがない場合はサーバーから取得。
    // getDocsFromCacheはキャッシュから取得できなかった場合でもerrorが発生しないのでemptyでチェックする
    // 本当のemptyとキャッシュがない場合のemptyを区別することができないので何か工夫が必要
    // また取得個数が 7 件以下の場合はキャッシュされない模様（実績ベース）
    // メッセージ数が少ない場合はキャッシュされないので注意
    consoleLog("[fs debug] getDocsFromServer");
    snapshot = await getDocsFromServer(query);
  }

  if (!snapshot) {
    consoleLog("[fs debug] getDocsFromServer 取得できませんでした。", query);
  } else if (snapshot.metadata.fromCache) {
    consoleLog("[fs debug] getDocsFromServer キャッシュから取得", query);
  } else {
    consoleLog("[fs debug] getDocsFromServer オンラインから取得", query._query.path.segments);
  }

  return snapshot!;
};

export const getDocFromCacheOrServer = async (
  docRef: DocumentReference,
): Promise<DocumentSnapshot<any>> => {
  let docSnap: any;
  try {
    // キャッシュから取得
    docSnap = await getDocFromCache(docRef);
  } catch (e) {
    consoleLog("[fs debug] getDocFromCache error", e);
  }
  if (!docSnap) {
    // キャッシュがない場合はサーバーから取得。
    // キャッシュから取得できなかった場合はgetDocFromCacheでerrorが発生する
    consoleLog("[fs debug] getDocFromServer");
    docSnap = await getDocFromServer(docRef);
  }
  if (!docSnap) {
    consoleLog("[fs debug] getDocFromCacheOrServer 取得できませんでした。", docRef);
  } else if (docSnap.metadata.fromCache) {
    consoleLog("[fs debug] getDocFromCacheOrServer キャッシュから取得", docRef);
  } else {
    consoleLog("[fs debug] getDocFromCacheOrServer オンラインから取得", docRef);
  }
  return docSnap;
};

const SHOW_CONSOLE_LOG = false; // デバッグ時は true にする
function consoleLog(message: string) {
  if (SHOW_CONSOLE_LOG) {
    console.log(message);
  }
}
