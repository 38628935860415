import revive_payload_client_4sVQNw7RlN from "/home/runner/work/fincs-webfront/fincs-webfront/src/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/fincs-webfront/fincs-webfront/src/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/fincs-webfront/fincs-webfront/src/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/home/runner/work/fincs-webfront/fincs-webfront/src/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/home/runner/work/fincs-webfront/fincs-webfront/src/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/fincs-webfront/fincs-webfront/src/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/fincs-webfront/fincs-webfront/src/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/fincs-webfront/fincs-webfront/src/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/fincs-webfront/fincs-webfront/src/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_client_Eorgdr12jA from "/home/runner/work/fincs-webfront/fincs-webfront/src/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/fincs-webfront/fincs-webfront/src/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/home/runner/work/fincs-webfront/fincs-webfront/src/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import filters_VNgPjx6zHj from "/home/runner/work/fincs-webfront/fincs-webfront/src/plugins/filters.ts";
import firebase_client_zXNDa0wxFH from "/home/runner/work/fincs-webfront/fincs-webfront/src/plugins/firebase.client.ts";
import repositories_mO66eqZn6a from "/home/runner/work/fincs-webfront/fincs-webfront/src/plugins/repositories.ts";
import sentry_3AyO8nEfhE from "/home/runner/work/fincs-webfront/fincs-webfront/src/plugins/sentry.ts";
import vuetify_7h9QAQEssH from "/home/runner/work/fincs-webfront/fincs-webfront/src/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_client_Eorgdr12jA,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  filters_VNgPjx6zHj,
  firebase_client_zXNDa0wxFH,
  repositories_mO66eqZn6a,
  sentry_3AyO8nEfhE,
  vuetify_7h9QAQEssH
]