// ref: https://github.com/Donaldcwl/browser-image-compression
import imageCompression, { type Options } from "browser-image-compression";
import heic2any from "heic2any";

// トリミングを有効にするか
export const enableTrimmingImage = true;

// サムネイル画像の圧縮ピクセルサイズ
export const thumbnailComplessWidth = 1280;

// 画像ファイルの対応拡張子
export const acceptableImgExtentions: string[] = [".png", ".jpg", ".jpeg", ".webp", ".heic"];
// プロフィール画像の対応拡張子
export const acceptableProfileImgExtentions: string[] = [".png", ".jpg", ".jpeg", ".webp", ".gif", ".heic"];
// トークアップロードの対応拡張子
export const acceptableTalkUploadExtentions: string[] = [".png", ".jpg", ".jpeg", ".webp", ".heic", ".pdf"];

// サムネイルアップロード時の注意書き
export const thumbnailUploadNotices = ["ファイル形式はJPG、PNG、WEBP、HEIF(.HEIC)のみ可能です"];

// サムネイルファイルのルール
export const thumbnailFileRules = [
  (f: File) => {
    return isFileExtentionAcceptable(f, acceptableImgExtentions) || "ファイル形式はJPG、PNG、WEBP、HEIF(.HEIC)のみです";
  },
];

// ファイルの拡張子が許可されているかをチェック
export const isFileExtentionAcceptable = (file: File, acceptableExtentions: string[]) => {
  return acceptableExtentions.some(extention => file.name.toLowerCase().endsWith(extention));
};

// ブラウザがwebpの変換をサポートしているかをチェック
export const supportsConvertWebP = () => {
  const canvas = document.createElement("canvas");
  if (canvas.getContext && canvas.getContext("2d")) {
    // 'image/webp'がサポートされているかをチェック
    return canvas.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  }
  return false;
};

// ファイルが画像ファイルかどうかを判定する
export const isImageFile = (file: File): boolean => {
  return ["image/jpeg", "image/png", "image/heic", "image/webp"].includes(file.type);
};

// ファイルがpdfファイルかどうかを判定する
export const isPdfFile = (file: File): boolean => {
  return ["application/pdf"].includes(file.type);
};

// Fileの拡張子を変更する
export const changeFileExtension = (file: File, ext: string): File => {
  const filename = file.name.replace(/\.[^/.]+$/, ext);
  const fileToReturn = new File([file], filename, { type: file.type });
  return fileToReturn;
};

// プロフィール用の画像ファイルを作成する
export const convertProfileImage = async (file: File): Promise<File> => {
  return await img2webp(file, {
    // https://invinc.slack.com/archives/C04HG0404HJ/p1708322190930689?thread_ts=1706006073.718849&cid=C04HG0404HJ
    maxWidthOrHeight: 300, // 最大ピクセルサイズ
  });
};

// コンテンツ用の画像ファイルを作成する
export const convertContentImage = async (file: File): Promise<File> => {
  const file2 = enableTrimmingImage ? await trimmingImage(file, thumbnailComplessWidth) : file;
  return await img2webp(file2, {
    maxWidthOrHeight: thumbnailComplessWidth, // 最大ピクセルサイズ
  });
};

// 講座詳細用の画像ファイルを作成する
export const convertPlanDetailImage = async (file: File): Promise<File> => {
  const file2 = enableTrimmingImage ? await trimmingImage(file, thumbnailComplessWidth) : file;
  return await img2webp(file2, {
    maxWidthOrHeight: thumbnailComplessWidth, // 最大ピクセルサイズ
  });
};

// トーク用の画像ファイルを作成する
export const convertTalkImage = async (file: File): Promise<File> => {
  return await img2webp(file, {});
};

// トーク用のサムネイルファイルを作成する
// base64を返す
export const convertTalkThumbnail = async (file: File): Promise<string> => {
  const compressedFile = await img2webp(file, {
    maxSizeMB: 0.02, // 最大ファイルサイズ
    maxWidthOrHeight: 512, // 最大ピクセルサイズ
  });

  const toBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const base64File = await toBase64(compressedFile);
  return base64File;
};

// 画像ファイルをファイル形式そのままで圧縮する
const imgCompress = async (file: File, options: Options) => {
  const mergeOptions = {
    ...options,
    useWebWorker: true, // trueにするとマルチスレッドで動く
  };
  return await imageCompression(file, mergeOptions);
};

// 画像ファイルをwebpに変換する
const img2webp = async (file: File, options: Options) => {
  // imageCompression は HEIC に対応していないため、HEIC の場合は変換する
  const fileToCompress = file.name.toLowerCase().endsWith(".heic") ? await heic2jpg(file) : file;

  // webp がサポートされていない場合は、画像形式そのままで圧縮する
  const webpSupported = supportsConvertWebP();
  if (!webpSupported) {
    return await imgCompress(fileToCompress as File, options);
  }

  const mergeOptions = {
    ...options,
    useWebWorker: true, // trueにするとマルチスレッドで動く
    fileType: "image/webp",
    initialQuality: 0.8, // 画像の品質設定（0から1の範囲で設定、高いほど品質が良くなる）
  };
  const compressedFile = await imageCompression(fileToCompress as File, mergeOptions);
  return changeFileExtension(compressedFile, ".webp");
};

// HEICファイルをJPGファイルに変換する
const heic2jpg = (heicFile: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const blob = new Blob([event.target.result]);
      heic2any({
        blob,
        toType: "image/jpeg",
      })
        .then(conversionResult => {
          const jpgFile = new File([conversionResult as Blob], heicFile.name + ".jpg", {
            type: "image/jpeg",
          });
          resolve(jpgFile);
        })
        .catch(e => {
          reject(e);
        });
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(heicFile);
  });
};

// 画像をトリミングする
const trimmingImageData = (data: string, fileType: string, maxWidth: number): Promise<string> => {
  return new Promise(resolve => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const aspect = 16 / 9;
      const width = Math.min(img.width, maxWidth);
      const height = width / aspect;

      // Canvasのサイズを設定
      canvas.width = width;
      canvas.height = height;

      // 元画像のトリミング対象部分を計算
      const drawWidth = img.width;
      const drawHeight = img.width / aspect;

      // 描画する画像のY座標を計算（中央にするために）
      const drawY = (img.height - drawHeight) / 2;

      // 中央に上下をトリミングして描画
      ctx.drawImage(
        img,
        0,
        drawY,
        drawWidth,
        drawHeight, // 元画像のトリミング部分
        0,
        0,
        width,
        height, // キャンバスへの描画範囲
      );

      resolve(canvas.toDataURL(fileType));
    };
    img.src = data;
  });
};

// 画像をトリミングする
export const trimmingImage = async (file: File, maxWidth: number): Promise<File> => {
  // imageCompression は HEIC に対応していないため、HEIC の場合は変換する
  const fileToCompress = file.name.toLowerCase().endsWith(".heic") ? await heic2jpg(file) : file;
  return new Promise<File>(resolve => {
    const fileReader = new FileReader();
    fileReader.onload = async function () {
      const img = await trimmingImageData(this.result as string, fileToCompress.type, maxWidth);
      // Fileに変換
      const blob = base64toBlob(img, fileToCompress.type);
      resolve(new File([blob], fileToCompress.name, { type: fileToCompress.type }));
    };
    fileReader.readAsDataURL(fileToCompress);
  });
};

// Base64からBlobを作成する
const base64toBlob = (base64: string, fileType: string) => {
  // Base64からバイナリへ変換
  const bin = atob(base64.split(",")[1]);
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  // Blobを作成
  const blob = new Blob([buffer.buffer], { type: fileType });
  return blob;
};

// Fileからプレビュー画像を取得する
export const previewImage = async (file: File): Promise<string> => {
  // 画像をプレビュー表示するために、画像をリサイズする
  let file2 = file;
  if (enableTrimmingImage) {
    file2 = await trimmingImage(file, thumbnailComplessWidth);
  } else if (file.name.toLowerCase().endsWith(".heic")) {
    file2 = await heic2jpg(file);
  }

  return new Promise<string>(resolve => {
    const fileReader = new FileReader();
    fileReader.onload = function () {
      resolve(this.result as string);
    };
    fileReader.readAsDataURL(file2);
  });
};
