import * as Sentry from "@sentry/browser";
import { type SeverityLevel } from "@sentry/types";
// import { useLoginStore } from "~/stores/LoginStore";
export const sentryErrorLog = (message: string): void => {
  // production 以外は console にも出力
  if (useRuntimeConfig().public.appEnv !== "production") {
    console.log(`sentryErrorLog: ${message}`);
  }

  // userIDはsentcyのユーザー情報に含まれているため、ログに出力する必要がない。一旦コメントアウト。
  // let outputMessage = message;
  // const loginStore = useLoginStore();
  // if (loginStore.loginInfo?.userId) {
  //   outputMessage += ` : userId=${loginStore.loginInfo?.userId}`;
  // }
  // Sentry.captureMessage(outputMessage, "error" as SeverityLevel);
  Sentry.captureMessage(message, "error" as SeverityLevel);
};

export const sentrySetUserId = (userId: string): void => {
  Sentry.setUser({
    id: userId,
  });
};
