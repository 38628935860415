<script setup lang="ts">
  import { PlanInfo } from "~/entities/PlanInfo";
  import { usePushNotificationStore } from "~/stores/PushNotificationStore";
  import { usePlanStore } from "~/stores/PlanStore";
  import { useLoginStore } from "~/stores/LoginStore";
  import { useSnackBarStore } from "~/stores/SnackBarStore";

  const planStore = usePlanStore();
  const loginStore = useLoginStore();
  const snackBarStore = useSnackBarStore();

  interface Props {
    planInfo: PlanInfo;
  }
  const props = withDefaults(defineProps<Props>(), {
    planInfo: undefined,
  });

  // push通知
  const pushNotificationStore = usePushNotificationStore();
  const browserInstallModal = ref(null);

  // push通知の登録
  const registerPushNotification = async () => {
    if (loginStore.isLogin() === false) {
      snackBarStore.set("プッシュ通知を受け取るにはログインしてください", "error");
      return;
    }
    if (isIOS() && !pushNotificationStore.notificationSupported()) {
      browserInstallModal.value?.openModal();
      return;
    }
    try {
      await pushNotificationStore.registerDeviceToken(true);
    } catch (e) {
      let errorMessage = "プッシュ通知の登録に失敗しました。通知設定をONにしてください";
      if (e instanceof ServiceWorkerNotActiveError) {
        // サービスワーカーが起動してない場合は別のエラーを表示する
        errorMessage = "通知を受け取る準備中です。数分時間を置いてから再度お試しください。";
      }
      // 失敗時はエラーを表示する
      snackBarStore.setError(errorMessage);
      sentryErrorLog(`プッシュ通知の登録に失敗(registerDeviceToken): ${e.message}`);
      return;
    }

    try {
      await pushNotificationStore.registerPushNotification(props.planInfo?.id.toString());
      snackBarStore.set(`${props.planInfo?.title}のプッシュ通知登録が完了しました`);
      await planStore.fetchOne(props.planInfo?.id.toString());
    } catch (e) {
      // 失敗時はエラーを表示する
      snackBarStore.setError("プッシュ通知の登録に失敗しました");
      sentryErrorLog(`プッシュ通知の登録に失敗: ${e.message}`);
    }
  };

  // push通知の解除
  const unregisterPushNotification = async () => {
    try {
      await pushNotificationStore.unregisterPushNotification(props.planInfo?.id.toString());
      snackBarStore.set(`${props.planInfo?.title}のプッシュ通知解除が完了しました`);
      await planStore.fetchOne(props.planInfo?.id.toString());
    } catch (e) {
      // 失敗時はエラーを表示する
      snackBarStore.setError("プッシュ通知の解除に失敗しました");
      sentryErrorLog(`プッシュ通知の解除に失敗: ${e.message}`);
    }
  };
</script>

<template>
  <div class="push-notification-btn-wrapper" data-testid="webpush-notification-btn">
    <v-btn
      v-if="planInfo?.isWebPushLinked"
      size="small"
      class="push-notification-btn push-notification-btn-disactive text-center rounded px-2"
      @click="unregisterPushNotification"
    >
      <img src="@/assets/images/icon/push-off.svg" class="mr-1" :width="18" alt="push" />
      講座のプッシュ通知解除
    </v-btn>
    <v-btn
      v-else
      size="small"
      class="push-notification-btn text-center bg-main-02 rounded px-2"
      @click="registerPushNotification"
    >
      <img src="@/assets/images/icon/push-on.svg" class="mr-1" :width="26" alt="push" />
      講座のプッシュ通知登録
    </v-btn>
    <AtomsBrowserInstallModal ref="browserInstallModal" />
  </div>
</template>

<style lang="scss" scoped>
  .push-notification-btn-wrapper {
    display: flex;
    align-items: center;
  }
  .push-notification-btn {
    width: 280px;
    height: 56px;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    &.push-notification-btn-disactive {
      background-color: #aaaaaa;
    }
  }
</style>
