import { defineStore } from "pinia";
import {
  getAuth,
  signOut as firebaseSignOut,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  type AuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  FacebookAuthProvider,
  type UserCredential,
  type User,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  RecaptchaVerifier,
  type MultiFactorResolver,
  getMultiFactorResolver,
  type MultiFactorError,
  PhoneMultiFactorGenerator,
  type PhoneMultiFactorInfo,
  PhoneAuthProvider,
  browserLocalPersistence,
  multiFactor,
  type PhoneInfoOptions,
} from "firebase/auth";
import { FirebaseError } from "firebase/app";

import { useTalkRoomViewStore } from "./TalkRoomViewStore";
import { useReceiptStore } from "./ReceiptStore";
import { useLoginStore } from "~/stores/LoginStore";
import { useSnackBarStore } from "~/stores/SnackBarStore";

type LoginUser = { id: string; name: string; email: string; uid: string; token: string };

export const enum SignInWithEmailResponse {
  EmailNotVerified = 1,
  MfaNotRegist = 2,
  MfaRequired = 3,
}

export const isFirebaseError = (arg: unknown): arg is FirebaseError => {
  return arg !== null && typeof arg === "object" && typeof (arg as FirebaseError).code === "string";
};

export const useAuthStore = defineStore("AuthStore", () => {
  // ベースURLを取得
  const getBaseUrl = () => window.location.origin;

  const token = ref<string | null>(null);
  const loginUser = ref<LoginUser>({
    id: "",
    name: "",
    email: "",
    uid: "",
    token: "",
  });

  // 2段階認証時にログイン時のerrorを使用するので取っておく
  const firebaseError = ref<FirebaseError | undefined>(undefined);

  // 2段階認証で使用する変数
  let recaptchaVerifier: RecaptchaVerifier | null = null;
  let resolver: MultiFactorResolver | null = null;
  let recaptchaId = -1;
  let recaptchaElementId = "";
  let verificationId = "";

  // 2段階認証デバイス
  const mfaDevice = ref<string>("");

  const loginStore = useLoginStore();

  const signOut = async (): Promise<void> => {
    // localStorageに保存しているものはログアウト時にリセットする
    const talkRoomViewStore = useTalkRoomViewStore();
    talkRoomViewStore.reset();
    const receiptStore = useReceiptStore();
    receiptStore.reset();

    await loginStore.logout();
    const auth = getAuth();
    await firebaseSignOut(auth);
    token.value = null;
  };

  const checkAuthState = (): void => {
    if (process.server) return;
    if (loginStore.loginInfo.sessionToken) {
      // TODO: 認証チェック処理を記述。一旦簡易的にセッションTokenの有無のみで判別
      token.value = loginStore.loginInfo.sessionToken;
    } else {
      token.value = null;
    }
  };

  // Google
  const signInWithGoogle = async (type: string, isRedirect: boolean): Promise<void> => {
    const provider = new GoogleAuthProvider();
    // Googleログイン時にアカウントが一つでも選択ポップアップを表示する (Googleのみ可能)
    // https://fincs.backlog.com/view/FINCS-1672
    provider.setCustomParameters({ prompt: "select_account" });
    const authType = useCookie("auth_type", { maxAge: 604800 });
    authType.value = type;
    await signInByAuthProvider(isRedirect, provider);
  };

  // X
  const signInWithX = async (type: string, isRedirect: boolean): Promise<void> => {
    const authType = useCookie("auth_type", { maxAge: 604800 });
    authType.value = type;
    await signInByAuthProvider(isRedirect, new TwitterAuthProvider());
  };

  // Facebook
  const signInWithFacebook = async (type: string, isRedirect: boolean): Promise<void> => {
    const authType = useCookie("auth_type", { maxAge: 604800 });
    authType.value = type;
    await signInByAuthProvider(isRedirect, new FacebookAuthProvider());
  };

  const signInByAuthProvider = async (
    isRedirect: boolean,
    provider: AuthProvider,
  ): Promise<void> => {
    const auth = getAuth();
    if (isRedirect) {
      await signInWithRedirect(auth, provider);
      return;
    }
    const userCredential = await signInWithPopup(auth, provider);
    await setTokenAndCredential(userCredential);
  };

  const afterSignInWithRedirect = async (): Promise<boolean> => {
    const auth = getAuth();
    const userCredential = await getRedirectResult(auth);
    if (userCredential == null) {
      return false; // 未認証なので何もしない
    }
    // 認証済みなら token と credential をセット
    await setTokenAndCredential(userCredential);
    return true;
  };

  const setTokenAndCredential = async (userCredential: UserCredential) => {
    token.value = await getAuthenticatedToken(userCredential.user);
    if (token.value != null) {
      loginUser.value.token = token.value;
    }
    setCredential(userCredential);
  };

  const setCredential = (userCredential: UserCredential): void => {
    loginUser.value.id = userCredential.user.uid;
    loginUser.value.name = userCredential.user.displayName ?? "";
    loginUser.value.email = userCredential.user.email ?? "";
  };

  const getAuthenticatedToken = async (user: User): Promise<string | null> => {
    // スナックバーstore
    const snackBarStore = useSnackBarStore();
    try {
      const idToken = await user.getIdToken();
      const authType = useCookie("auth_type", { maxAge: 604800 });
      if (authType.value === "signin") {
        await loginStore.login(idToken);
      } else if (authType.value === "signup") {
        await loginStore.register(idToken);
      }

      if (loginStore.loginInfo.statusCode === 200) {
        return loginStore.loginInfo.sessionToken;
      } else {
        // 問い合わせ時に必要なのでfirebaseのユーザーIDをセット
        sentrySetUserId(user.uid);
        sentryErrorLog(`login fail: ${JSON.stringify(loginStore.loginInfo)}`);
        return null;
      }
    } catch (error: any) {
      if (error.data?.status_code === 404) {
        snackBarStore.set(
          "アカウントが見つかりませんでした。まだアカウントをお持ちでない場合は、新規登録を行なってください。",
          "error",
        );
        // 問い合わせ時に必要なのでfirebaseのユーザーIDをセット
        sentrySetUserId(user.uid);
        sentryErrorLog(`login error: 未登録`);
      } else if (error.data?.status_code === 409) {
        snackBarStore.set(
          "すでに登録済みのアカウントです。ログインタブからログインを行なってください。",
          "error",
        );
      } else {
        // 問い合わせ時に必要なのでfirebaseのユーザーIDをセット
        sentrySetUserId(user.uid);
        sentryErrorLog(`login error: ${JSON.stringify(error)}`);
      }
      return null;
    }
  };

  const getAuthenticatedTokenAdmin = async (user: User): Promise<string | null> => {
    try {
      const idToken = await user.getIdToken();
      await loginStore.adminLogin(idToken);
      if (loginStore.loginInfo.statusCode === 200) {
        return loginStore.loginInfo.sessionToken;
      } else {
        // 問い合わせ時に必要なのでfirebaseのユーザーIDをセット
        sentrySetUserId(user.uid);
        sentryErrorLog(`adminLogin fail: ${JSON.stringify(loginStore.loginInfo)}`);
        return null;
      }
    } catch (error) {
      // 問い合わせ時に必要なのでfirebaseのユーザーIDをセット
      sentrySetUserId(user.uid);
      sentryErrorLog(`adminLogin error:: ${JSON.stringify(error)}`);
      return null;
    }
  };

  // email,passwordで登録
  const signUpWithEmail = async (email: string, password: string): Promise<void> => {
    const auth = getAuth();
    await createUserWithEmailAndPassword(auth, email, password);
  };

  const checkLoginUser = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      console.log(await user.getIdToken());
    } else {
      console.log("none user");
    }
  };

  /**
   * email,passwordで管理者ログイン
   * 処理フロー
   * email認証NG -> SignInWithEmailResponse.EmailNotVerifiedでメール認証させる
   * email認証OK、2段階認証登録まだ -> SignInWithEmailResponse.MfaNotRegistで2段階認証登録させる
   * email認証OK、2段階認証登録済み -> auth/multi-factor-auth-requiredとなるので２段階認証をさせる
   * @param email
   * @param password
   * @returns
   */
  const signInWithEmail = async (
    email: string,
    password: string,
  ): Promise<SignInWithEmailResponse> => {
    try {
      const auth = getAuth();
      await auth.setPersistence(browserLocalPersistence);
      const result = await signInWithEmailAndPassword(auth, email, password);
      if (!result.user.emailVerified) {
        // email認証がまだ
        return SignInWithEmailResponse.EmailNotVerified;
      }
    } catch (error: unknown) {
      if (!isFirebaseError(error) || error.code !== "auth/multi-factor-auth-required") {
        throw error;
      }
      firebaseError.value = error as FirebaseError;
      // catchの中なので紛らわしいが、ここに来るのが正常終了。この後２段階認証をしてもらう
      return SignInWithEmailResponse.MfaRequired;
    }
    // 管理者ログインは2段階認証必須なのでここに到達するということは、2段階認証が登録されていないということ。
    return SignInWithEmailResponse.MfaNotRegist;
  };

  // email認証のためのメールを送信する
  const sendVerifyEmail = async (verifyPath: string): Promise<void> => {
    try {
      const auth = getAuth();
      if (!auth.currentUser) {
        await navigateTo("/admin/signup");
        return;
      }
      await sendEmailVerification(auth.currentUser, {
        url: getBaseUrl() + verifyPath,
        handleCodeInApp: true,
      });
    } catch (e) {
      alert(e);
    }
  };

  /**
   * Recaptchaを開始する
   * @param elementId divなどのHTML内でrecaptchaを描画するHTMLのidを指定する
   */
  const setRecaptcha = async (elementId: string): Promise<void> => {
    const auth = getAuth();
    recaptchaElementId = elementId;
    recaptchaVerifier = new RecaptchaVerifier(
      elementId,
      {
        size: "invisible",
      },
      auth,
    );
    recaptchaId = await recaptchaVerifier.render();
  };

  /**
   * MFAデバイスを列挙し、最初のデバイス名をmfaDeviceに格納する
   */
  const getMFADevice = (): void => {
    const auth = getAuth();
    resolver = getMultiFactorResolver(auth, firebaseError.value as MultiFactorError);
    if (!resolver) {
      throw new Error("resolverを作成できませんでした");
    }
    const mfaDevices = resolver.hints.map(item => {
      if (item.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
        return (item as PhoneMultiFactorInfo).phoneNumber ?? "unknown device";
      }
      return "unknown device";
    });
    if (mfaDevices) {
      mfaDevice.value = mfaDevices[0];
    }
  };

  /**
   * 2段階認証コードをデバイスに送信し登録する
   */
  const registMFADevice = async (phoneNumber: string): Promise<void> => {
    const auth = getAuth();
    if (recaptchaId === -1 || !recaptchaVerifier) {
      await setRecaptcha(recaptchaElementId);
      throw new Error("recaptchaに失敗しました。再度実行してください。");
    }
    if (!auth || !auth.currentUser) {
      // throw new Error("firebaseのauth取得エラー。");
      throw new Error("送信に失敗しました。ログインし直してください。");
    }
    try {
      const multiFactorSession = await multiFactor(auth.currentUser).getSession();
      const phoneInfoOptions: PhoneInfoOptions = {
        phoneNumber: phoneNumber,
        session: multiFactorSession,
      };
      verificationId = await new PhoneAuthProvider(auth).verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier,
      );
    } catch (error) {
      // しばらくログインしてない場合はエラーになるのでログインを再度求める
      if (isFirebaseError(error) && error.code === "auth/requires-recent-login") {
        throw new Error("送信に失敗しました。ログインし直してください。");
      }
      throw error;
    }
  };

  /**
   * 2段階認証コードを登録する
   */
  const registMFACode = async (verificationCode: string): Promise<void> => {
    const auth = getAuth();
    if (!verificationCode) {
      throw new Error("認証コードを入力してください。");
    }
    if (recaptchaId === -1 || !recaptchaVerifier) {
      await setRecaptcha(recaptchaElementId);
      throw new Error("recaptchaに失敗しました。再度実行してください。");
    }
    if (!auth || !auth.currentUser) {
      // throw new Error("firebaseのauth取得エラー。");
      throw new Error("認証に失敗しました。ログインし直してください。");
    }

    try {
      const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await multiFactor(auth.currentUser).enroll(multiFactorAssertion);
    } catch (e) {
      throw new Error("認証に失敗しました1");
    }
    try {
      token.value = await getAuthenticatedTokenAdmin(auth.currentUser);
      if (token.value != null) {
        loginUser.value.token = token.value;
      }
    } catch (e) {
      throw new Error("認証に失敗しました2");
    }
  };
  /**
   * 2段階認証コードをデバイスに送信する
   */
  const sendMFACode = async (): Promise<void> => {
    const auth = getAuth();
    if (recaptchaId === -1 || !recaptchaVerifier) {
      await setRecaptcha(recaptchaElementId);
      throw new Error("recaptchaに失敗しました。再度実行してください。");
    }
    if (!auth) {
      // throw new Error("firebaseのauth取得エラー。");
      throw new Error("送信に失敗しました。ログインし直してください。");
    }
    resolver = getMultiFactorResolver(auth, firebaseError.value as MultiFactorError);
    if (!resolver) {
      throw new Error("resolverを作成できませんでした");
    }

    const selectedIndex = 0; // ホントはhintsを見て、多要素認証を選択させるように作るこむといい
    if (resolver.hints[selectedIndex].factorId !== PhoneMultiFactorGenerator.FACTOR_ID) {
      // 携帯電話での2段階認証でなければエラー
      throw new Error("電話での2段階認証ではありません");
    }
    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[selectedIndex],
      session: resolver.session,
    };
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    // Send SMS verification code
    verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
  };

  /**
   * 2段階認証コードで認証する
   */
  const verifyMFACode = async (verificationCode: string): Promise<void> => {
    const auth = getAuth();
    if (!verificationCode) {
      throw new Error("認証コードを入力してください。");
    }
    if (recaptchaId === -1 || !recaptchaVerifier) {
      await setRecaptcha(recaptchaElementId);
      throw new Error("recaptchaに失敗しました。再度実行してください。");
    }
    if (!auth) {
      // throw new Error("firebaseのauth取得エラー。");
      throw new Error("認証に失敗しました。ログインし直してください。");
    }
    resolver = getMultiFactorResolver(auth, firebaseError.value as MultiFactorError);
    if (!resolver) {
      throw new Error("resolverを作成できませんでした");
    }

    try {
      const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      // Complete sign-in.
      const userCredential = await resolver.resolveSignIn(multiFactorAssertion);
      token.value = await getAuthenticatedTokenAdmin(userCredential.user);
      if (token.value != null) {
        loginUser.value.token = token.value;
      }
      setCredential(userCredential);
    } catch (e) {
      throw new Error("認証に失敗しました");
    }
  };

  /**
   * 認証トークンをRefreshトークンを使用して再取得する
   */
  const refreshToken = async (): Promise<void> => {
    await loginStore.refresh();
  };

  return {
    token,
    refreshToken,
    loginUser,
    firebaseError,
    mfaDevice,
    signOut,
    checkAuthState,
    afterSignInWithRedirect,
    signInWithGoogle,
    signInWithX,
    signInWithFacebook,
    signUpWithEmail,
    signInWithEmail,
    sendVerifyEmail,
    setRecaptcha,
    getMFADevice,
    registMFADevice,
    registMFACode,
    sendMFACode,
    verifyMFACode,
    checkLoginUser,
  };
});
