import { useDisplay } from "vuetify";

export const isSpDisplayRef = (): Ref<boolean> => {
  const { name } = useDisplay();
  return computed<boolean>(() => name.value === "xs");
};

export const isTabletAndSpDisplayRef = (): Ref<boolean> => {
  const { name } = useDisplay();
  return computed<boolean>(() => name.value === "xs" || name.value === "sm");
};

// コンテンツカードの横幅
export const getContentCardWidth = (): Ref<number> => {
  return computed<number>(() => {
    return Math.min(330, window.screen.width - 48);
  });
};

// オーナーカードの横幅
export const getOwnerCardWidth = (): Ref<number> => {
  return computed<number>(() => {
    return Math.min(330, window.screen.width - 48);
  });
};

// 講座カードの横幅
export const getPlanCardWidth = (): Ref<number> => {
  return computed<number>(() => {
    return Math.min(330, window.screen.width - 48);
  });
};

export const requestFullScreen = (element: any) => {
  if (element.requestFullScreen) {
    element.requestFullScreen();
  } else if (element.webkitRequestFullScreen) {
    element.webkitRequestFullScreen();
  } else if (element.webkitEnterFullscreen) {
    element.webkitEnterFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
};

export const exitFullScreen = (element: any) => {
  if (element.exitFullscreen) {
    element.exitFullscreen();
  } else if (element.cancelFullScreen) {
    element.cancelFullScreen();
  } else if (element.mozCancelFullScreen) {
    element.mozCancelFullScreen();
  } else if (element.webkitCancelFullScreen) {
    element.webkitCancelFullScreen();
  } else if (element.msExitFullscreen) {
    element.msExitFullscreen();
  }
};

export const isIOS = () => {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true;
  } else {
    return navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
  }
};

export const isSafari = () => {
  // iOS の場合、ブラウザは必ず webkit ベースなので、 safari とみなす
  // ref: https://qiita.com/pink/items/a54b8cadbe39a06956a9
  if (isIOS()) {
    return true;
  }

  // その他の OS の場合
  // ref: https://www.whatismybrowser.com/guides/the-latest-user-agent/
  return (
    /Safari/.test(navigator.userAgent) &&
    // PC Chrome および Edge も Safari を含むが、 Chrome も併せ持つのでそれを除外判定
    !/Chrome/.test(navigator.userAgent) &&
    // SP Chrome および Edge も Safari を含むが、 CriOS も併せ持つのでそれを除外判定
    !/CriOS/.test(navigator.userAgent)
  );
};

export const isPWA = () => {
  if (window.matchMedia("(display-mode: standalone)").matches) {
    return true;
  }
  return false;
};
