import { defineStore } from "pinia";

export const useErrorStore = defineStore("ErrorStore", () => {
  const isOpen = ref(false);
  const dialogMessage = ref<string>("");
  const buttonTitle = ref<string>("");
  let complete = () => {};

  const show = (message: string, button: string, completed: () => void) => {
    dialogMessage.value = message;
    buttonTitle.value = button;
    isOpen.value = true;
    complete = completed;
  };

  const showConnectionError = () => {
    show("接続に失敗しました\n通信環境を確認の上、再試行してください", "再試行", () => {
      location.reload();
    });
  };

  const close = () => {
    complete();
    isOpen.value = false;
  };

  const reset = () => {
    isOpen.value = false;
  };

  return {
    isOpen,
    dialogMessage,
    buttonTitle,
    show,
    showConnectionError,
    close,
    reset,
  };
});
