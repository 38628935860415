import { Ogp } from "~/entities/Ogp";
import type { IOgpRepository } from "~/interfaces/IOgpRepository";

export class OgpRepository implements IOgpRepository {
  async getOgp(url: string): Promise<any> {
    const res = await GetRequest<{ statusCode: number; ogpList: Ogp }>(
      `/ogp` + "?url=" + encodeURIComponent(url),
    );
    if (!res || res.statusCode !== 200) {
      throw new Error("Failed get ogp");
    } else {
      return res.ogpList;
    }
  }
}
