import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import {
  mdiInformation,
  mdiChevronUp,
  mdiPause,
  mdiCheckboxMarkedOutline,
  mdiPaperclip,
  mdiCheck,
  mdiChevronRight,
  mdiChevronDown,
  mdiChevronLeft,
  mdiThumbUp,
  mdiEyeOff,
  mdiClose,
  mdiPauseCircle,
  mdiPlayCircle,
  mdiCamera,
  mdiContentCopy,
  mdiGoogle,
  mdiEye,
  mdiCheckboxBlankOutline,
  mdiPlus,
  mdiStar,
  mdiCloseBox,
  mdiPlay,
  mdiShare,
  mdiThumbUpOutline,
  mdiDotsVertical,
  mdiMenuDown,
  mdiMenuRight,
  mdiAccountGroup,
  mdiMagnify,
  mdiDownload,
  mdiSortVariant,
  mdiSortReverseVariant,
  mdiPoll,
  mdiCogOutline,
  mdiImage,
  mdiVideo,
  mdiFormatBold,
  mdiFormatItalic,
  mdiFormatUnderline,
  mdiFormatStrikethrough,
  mdiLink,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiUndo,
  mdiRedo,
  mdiCheckCircle,
  mdiSwapHorizontal,
  mdiFilter,
  mdiFilterCheck,
  mdiAccountMultiple,
  mdiOpenInNew,
  mdiLinkVariant,
} from "@mdi/js";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { fincsTheme } from "~/consts/VuetifyTheme";

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify({
    ssr: true,
    components,
    directives,
    theme: {
      defaultTheme: "fincsTheme",
      themes: {
        fincsTheme,
      },
    },
    icons: {
      defaultSet: "mdi",
      aliases: {
        ...aliases,
        mdiInformation: mdiInformation,
        mdiChevronUp: mdiChevronUp,
        mdiPause: mdiPause,
        mdiCheckboxMarkedOutline: mdiCheckboxMarkedOutline,
        mdiPaperclip: mdiPaperclip,
        mdiCheck: mdiCheck,
        mdiChevronRight: mdiChevronRight,
        mdiChevronDown: mdiChevronDown,
        mdiChevronLeft: mdiChevronLeft,
        mdiThumbUp: mdiThumbUp,
        mdiEyeOff: mdiEyeOff,
        mdiClose: mdiClose,
        mdiPauseCircle: mdiPauseCircle,
        mdiPlayCircle: mdiPlayCircle,
        mdiCamera: mdiCamera,
        mdiContentCopy: mdiContentCopy,
        mdiGoogle: mdiGoogle,
        mdiEye: mdiEye,
        mdiCheckboxBlankOutline: mdiCheckboxBlankOutline,
        mdiPlus: mdiPlus,
        mdiStar: mdiStar,
        mdiCloseBox: mdiCloseBox,
        mdiPlay: mdiPlay,
        mdiShare: mdiShare,
        mdiThumbUpOutline: mdiThumbUpOutline,
        mdiDotsVertical: mdiDotsVertical,
        mdiMenuDown: mdiMenuDown,
        mdiMenuRight: mdiMenuRight,
        mdiAccountGroup: mdiAccountGroup,
        mdiMagnify: mdiMagnify,
        mdiDownload: mdiDownload,
        mdiSortVariant: mdiSortVariant,
        mdiSortReverseVariant: mdiSortReverseVariant,
        mdiPoll: mdiPoll,
        mdiCogOutline: mdiCogOutline,
        mdiImage: mdiImage,
        mdiVideo: mdiVideo,
        mdiFormatBold: mdiFormatBold,
        mdiFormatItalic: mdiFormatItalic,
        mdiFormatUnderline: mdiFormatUnderline,
        mdiFormatStrikethrough: mdiFormatStrikethrough,
        mdiLink: mdiLink,
        mdiFormatListBulleted: mdiFormatListBulleted,
        mdiFormatListNumbered: mdiFormatListNumbered,
        mdiUndo: mdiUndo,
        mdiRedo: mdiRedo,
        mdiCheckCircle: mdiCheckCircle,
        mdiSwapHorizontal: mdiSwapHorizontal,
        mdiFilter: mdiFilter,
        mdiFilterCheck: mdiFilterCheck,
        mdiAccountMultiple: mdiAccountMultiple,
        mdiOpenInNew: mdiOpenInNew,
        mdiLinkVariant: mdiLinkVariant,
      },
      sets: {
        mdi,
      },
    },
  });
  nuxtApp.vueApp.use(vuetify);
});
