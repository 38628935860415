import { default as logind0Qqeooh07Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/login.vue?macro=true";
import { default as regist_mfapGooDlm156Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/regist_mfa.vue?macro=true";
import { default as send_emailgtAgXpK3mtMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/send_email.vue?macro=true";
import { default as signupJqDHuga3v5Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/signup.vue?macro=true";
import { default as tooluhhCvghyCgMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/tool.vue?macro=true";
import { default as verify_mfa3O8u5mo7sfMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/verify_mfa.vue?macro=true";
import { default as indexAMVR1MppuyMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/document/[id]/index.vue?macro=true";
import { default as indexXMghH72TH4Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/index.vue?macro=true";
import { default as line_link_finish4uSIMAyjLaMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/line_link_finish.vue?macro=true";
import { default as line_link_revoke_finishMXLN9X1FUYMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/line_link_revoke_finish.vue?macro=true";
import { default as line_link_revoke4HcZ7TIgeHMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/line_link_revoke.vue?macro=true";
import { default as line_redirectFftxViHlYSMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/line_redirect.vue?macro=true";
import { default as hostAKcx5rHD6eMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/live/[id]/host.vue?macro=true";
import { default as indexZ6lrMP6ZLvMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/live/[id]/index.vue?macro=true";
import { default as resultdkGbdgWigmMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/live/[id]/result.vue?macro=true";
import { default as _91id_937mnQNjVQ0kMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/livearchive/[id].vue?macro=true";
import { default as _91id_93TtRczRcjfLMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/liveschedule/[id].vue?macro=true";
import { default as loginn9mrUV14R2Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/login.vue?macro=true";
import { default as embedULW98ihf3yMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/movie/[id]/embed.vue?macro=true";
import { default as indexzTwvbf4FUlMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/movie/[id]/index.vue?macro=true";
import { default as emailfOq3CDFhgrMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/mypage/email.vue?macro=true";
import { default as indexKJ25IUbbwMMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/mypage/index.vue?macro=true";
import { default as profileNl6CDcgUJTMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/mypage/profile.vue?macro=true";
import { default as _91id_937IwTTQPD7TMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/owner/[id].vue?macro=true";
import { default as createEnGwZA49ZGMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/payment/create.vue?macro=true";
import { default as history7i5eXbTFoTMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/payment/history.vue?macro=true";
import { default as indextW5Be7sUH9Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/payment/index.vue?macro=true";
import { default as addpaymentDXArqP5HBnMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/addpayment.vue?macro=true";
import { default as completesW3S5GIfwgMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/complete.vue?macro=true";
import { default as confirmZaJN544jSMMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/confirm.vue?macro=true";
import { default as noticeocPvnygYG8Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/notice.vue?macro=true";
import { default as reasonPgWqfryCjvMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/reason.vue?macro=true";
import { default as starthlhSNoMOrIMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/start.vue?macro=true";
import { default as indexjvPGDBbHRdMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/contract/index.vue?macro=true";
import { default as indexh5wtssseCzMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/contract/payment/index.vue?macro=true";
import { default as newZtC8agqjbyMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/contract/payment/new.vue?macro=true";
import { default as completeGwgb7Zmwb2Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/complete.vue?macro=true";
import { default as editC4Feea3JceMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/document/[contentId]/edit.vue?macro=true";
import { default as createx4X1lzIP0gMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/document/create.vue?macro=true";
import { default as edit2CVxn6OxfUMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/live/[contentId]/edit.vue?macro=true";
import { default as createNM6LflVeKKMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/live/create.vue?macro=true";
import { default as editJlKNPkn16VMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/livearchive/[contentId]/edit.vue?macro=true";
import { default as editwtSXXROGNWMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/movie/[contentId]/edit.vue?macro=true";
import { default as creates2qPeuWwYHMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/movie/create.vue?macro=true";
import { default as historyma1XlulGeBMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/offer/[campaignId]/history.vue?macro=true";
import { default as createKlJ8S3lejMMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/offer/create.vue?macro=true";
import { default as edit5SIfklITIjMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/offer/edit.vue?macro=true";
import { default as indexA6UVoPlMZzMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/document/[contentId]/index.vue?macro=true";
import { default as indexWuTtb94UTfMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/edit/index.vue?macro=true";
import { default as indexv97DdaNqmIMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/index.vue?macro=true";
import { default as addpaymentsq42VhWgW6Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/addpayment.vue?macro=true";
import { default as agreementGFEsJTPCAHMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/agreement.vue?macro=true";
import { default as authemailKy7LhKo6XiMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/authemail.vue?macro=true";
import { default as complete0yCd5pXbWvMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/complete.vue?macro=true";
import { default as confirmOHzc6ixkgiMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/confirm.vue?macro=true";
import { default as documents9wlJZrMmxDMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/documents.vue?macro=true";
import { default as payment_45bankDTelTLt7h2Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/payment-bank.vue?macro=true";
import { default as payment_45cardzpYvXwfe8UMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/payment-card.vue?macro=true";
import { default as payment_45methodWLUKCmDqo5Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/payment-method.vue?macro=true";
import { default as personalinfo07Z9455hNFMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/personalinfo.vue?macro=true";
import { default as createxCnAO2BpJXMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/library/create.vue?macro=true";
import { default as edit4Muye1m9ZjMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/library/edit.vue?macro=true";
import { default as hosts4BLQaSyvrMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/live/[contentId]/host.vue?macro=true";
import { default as indexyqggVynpr9Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/live/[contentId]/index.vue?macro=true";
import { default as result1CrO4LwJ8pMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/live/[contentId]/result.vue?macro=true";
import { default as indexhE8evGGaLRMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/livearchive/[contentId]/index.vue?macro=true";
import { default as indexTj5ezZ4UOpMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/liveschedule/[contentId]/index.vue?macro=true";
import { default as embedn4LBFd02fzMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/movie/[contentId]/embed.vue?macro=true";
import { default as indexFBLyS66WD7Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/movie/[contentId]/index.vue?macro=true";
import { default as paymentpPMmpJbGdkMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/payment.vue?macro=true";
import { default as personalinfob1skzVSnDpMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/personalinfo.vue?macro=true";
import { default as editkJCxeglNl1Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/price/[priceId]/edit.vue?macro=true";
import { default as history19JuObZM8tMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/price/[priceId]/history.vue?macro=true";
import { default as createOEcRhcoskGMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/price/create.vue?macro=true";
import { default as completecmR7yrUJ5PMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/review/complete.vue?macro=true";
import { default as indexCTMb6bIQ4TMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/review/index.vue?macro=true";
import { default as index8GQbyMPh8PMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/reviews/index.vue?macro=true";
import { default as editSscvfP2vQ3Meta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/talk/[talkRoomId]/edit.vue?macro=true";
import { default as editdm0vlkjdo2KyMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/talk/[talkRoomId]/editdm.vue?macro=true";
import { default as create93acIT57TkMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/talk/create.vue?macro=true";
import { default as joiningLi8xx6I92EMeta } from "/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plans/joining.vue?macro=true";
export default [
  {
    name: logind0Qqeooh07Meta?.name ?? "admin-login",
    path: logind0Qqeooh07Meta?.path ?? "/admin/login",
    meta: logind0Qqeooh07Meta || {},
    alias: logind0Qqeooh07Meta?.alias || [],
    redirect: logind0Qqeooh07Meta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: regist_mfapGooDlm156Meta?.name ?? "admin-regist_mfa",
    path: regist_mfapGooDlm156Meta?.path ?? "/admin/regist_mfa",
    meta: regist_mfapGooDlm156Meta || {},
    alias: regist_mfapGooDlm156Meta?.alias || [],
    redirect: regist_mfapGooDlm156Meta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/regist_mfa.vue").then(m => m.default || m)
  },
  {
    name: send_emailgtAgXpK3mtMeta?.name ?? "admin-send_email",
    path: send_emailgtAgXpK3mtMeta?.path ?? "/admin/send_email",
    meta: send_emailgtAgXpK3mtMeta || {},
    alias: send_emailgtAgXpK3mtMeta?.alias || [],
    redirect: send_emailgtAgXpK3mtMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/send_email.vue").then(m => m.default || m)
  },
  {
    name: signupJqDHuga3v5Meta?.name ?? "admin-signup",
    path: signupJqDHuga3v5Meta?.path ?? "/admin/signup",
    meta: signupJqDHuga3v5Meta || {},
    alias: signupJqDHuga3v5Meta?.alias || [],
    redirect: signupJqDHuga3v5Meta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/signup.vue").then(m => m.default || m)
  },
  {
    name: tooluhhCvghyCgMeta?.name ?? "admin-tool",
    path: tooluhhCvghyCgMeta?.path ?? "/admin/tool",
    meta: tooluhhCvghyCgMeta || {},
    alias: tooluhhCvghyCgMeta?.alias || [],
    redirect: tooluhhCvghyCgMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/tool.vue").then(m => m.default || m)
  },
  {
    name: verify_mfa3O8u5mo7sfMeta?.name ?? "admin-verify_mfa",
    path: verify_mfa3O8u5mo7sfMeta?.path ?? "/admin/verify_mfa",
    meta: verify_mfa3O8u5mo7sfMeta || {},
    alias: verify_mfa3O8u5mo7sfMeta?.alias || [],
    redirect: verify_mfa3O8u5mo7sfMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/admin/verify_mfa.vue").then(m => m.default || m)
  },
  {
    name: indexAMVR1MppuyMeta?.name ?? "document-id",
    path: indexAMVR1MppuyMeta?.path ?? "/document/:id()",
    meta: indexAMVR1MppuyMeta || {},
    alias: indexAMVR1MppuyMeta?.alias || [],
    redirect: indexAMVR1MppuyMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/document/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexXMghH72TH4Meta?.name ?? "index",
    path: indexXMghH72TH4Meta?.path ?? "/",
    meta: indexXMghH72TH4Meta || {},
    alias: indexXMghH72TH4Meta?.alias || [],
    redirect: indexXMghH72TH4Meta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: line_link_finish4uSIMAyjLaMeta?.name ?? "line_link_finish",
    path: line_link_finish4uSIMAyjLaMeta?.path ?? "/line_link_finish",
    meta: line_link_finish4uSIMAyjLaMeta || {},
    alias: line_link_finish4uSIMAyjLaMeta?.alias || [],
    redirect: line_link_finish4uSIMAyjLaMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/line_link_finish.vue").then(m => m.default || m)
  },
  {
    name: line_link_revoke_finishMXLN9X1FUYMeta?.name ?? "line_link_revoke_finish",
    path: line_link_revoke_finishMXLN9X1FUYMeta?.path ?? "/line_link_revoke_finish",
    meta: line_link_revoke_finishMXLN9X1FUYMeta || {},
    alias: line_link_revoke_finishMXLN9X1FUYMeta?.alias || [],
    redirect: line_link_revoke_finishMXLN9X1FUYMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/line_link_revoke_finish.vue").then(m => m.default || m)
  },
  {
    name: line_link_revoke4HcZ7TIgeHMeta?.name ?? "line_link_revoke",
    path: line_link_revoke4HcZ7TIgeHMeta?.path ?? "/line_link_revoke",
    meta: line_link_revoke4HcZ7TIgeHMeta || {},
    alias: line_link_revoke4HcZ7TIgeHMeta?.alias || [],
    redirect: line_link_revoke4HcZ7TIgeHMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/line_link_revoke.vue").then(m => m.default || m)
  },
  {
    name: line_redirectFftxViHlYSMeta?.name ?? "line_redirect",
    path: line_redirectFftxViHlYSMeta?.path ?? "/line_redirect",
    meta: line_redirectFftxViHlYSMeta || {},
    alias: line_redirectFftxViHlYSMeta?.alias || [],
    redirect: line_redirectFftxViHlYSMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/line_redirect.vue").then(m => m.default || m)
  },
  {
    name: hostAKcx5rHD6eMeta?.name ?? "live-id-host",
    path: hostAKcx5rHD6eMeta?.path ?? "/live/:id()/host",
    meta: hostAKcx5rHD6eMeta || {},
    alias: hostAKcx5rHD6eMeta?.alias || [],
    redirect: hostAKcx5rHD6eMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/live/[id]/host.vue").then(m => m.default || m)
  },
  {
    name: indexZ6lrMP6ZLvMeta?.name ?? "live-id",
    path: indexZ6lrMP6ZLvMeta?.path ?? "/live/:id()",
    meta: indexZ6lrMP6ZLvMeta || {},
    alias: indexZ6lrMP6ZLvMeta?.alias || [],
    redirect: indexZ6lrMP6ZLvMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/live/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: resultdkGbdgWigmMeta?.name ?? "live-id-result",
    path: resultdkGbdgWigmMeta?.path ?? "/live/:id()/result",
    meta: resultdkGbdgWigmMeta || {},
    alias: resultdkGbdgWigmMeta?.alias || [],
    redirect: resultdkGbdgWigmMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/live/[id]/result.vue").then(m => m.default || m)
  },
  {
    name: _91id_937mnQNjVQ0kMeta?.name ?? "livearchive-id",
    path: _91id_937mnQNjVQ0kMeta?.path ?? "/livearchive/:id()",
    meta: _91id_937mnQNjVQ0kMeta || {},
    alias: _91id_937mnQNjVQ0kMeta?.alias || [],
    redirect: _91id_937mnQNjVQ0kMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/livearchive/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93TtRczRcjfLMeta?.name ?? "liveschedule-id",
    path: _91id_93TtRczRcjfLMeta?.path ?? "/liveschedule/:id()",
    meta: _91id_93TtRczRcjfLMeta || {},
    alias: _91id_93TtRczRcjfLMeta?.alias || [],
    redirect: _91id_93TtRczRcjfLMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/liveschedule/[id].vue").then(m => m.default || m)
  },
  {
    name: loginn9mrUV14R2Meta?.name ?? "login",
    path: loginn9mrUV14R2Meta?.path ?? "/login",
    meta: loginn9mrUV14R2Meta || {},
    alias: loginn9mrUV14R2Meta?.alias || [],
    redirect: loginn9mrUV14R2Meta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: embedULW98ihf3yMeta?.name ?? "movie-id-embed",
    path: embedULW98ihf3yMeta?.path ?? "/movie/:id()/embed",
    meta: embedULW98ihf3yMeta || {},
    alias: embedULW98ihf3yMeta?.alias || [],
    redirect: embedULW98ihf3yMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/movie/[id]/embed.vue").then(m => m.default || m)
  },
  {
    name: indexzTwvbf4FUlMeta?.name ?? "movie-id",
    path: indexzTwvbf4FUlMeta?.path ?? "/movie/:id()",
    meta: indexzTwvbf4FUlMeta || {},
    alias: indexzTwvbf4FUlMeta?.alias || [],
    redirect: indexzTwvbf4FUlMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/movie/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: emailfOq3CDFhgrMeta?.name ?? "mypage-email",
    path: emailfOq3CDFhgrMeta?.path ?? "/mypage/email",
    meta: emailfOq3CDFhgrMeta || {},
    alias: emailfOq3CDFhgrMeta?.alias || [],
    redirect: emailfOq3CDFhgrMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/mypage/email.vue").then(m => m.default || m)
  },
  {
    name: indexKJ25IUbbwMMeta?.name ?? "mypage",
    path: indexKJ25IUbbwMMeta?.path ?? "/mypage",
    meta: indexKJ25IUbbwMMeta || {},
    alias: indexKJ25IUbbwMMeta?.alias || [],
    redirect: indexKJ25IUbbwMMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/mypage/index.vue").then(m => m.default || m)
  },
  {
    name: profileNl6CDcgUJTMeta?.name ?? "mypage-profile",
    path: profileNl6CDcgUJTMeta?.path ?? "/mypage/profile",
    meta: profileNl6CDcgUJTMeta || {},
    alias: profileNl6CDcgUJTMeta?.alias || [],
    redirect: profileNl6CDcgUJTMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/mypage/profile.vue").then(m => m.default || m)
  },
  {
    name: _91id_937IwTTQPD7TMeta?.name ?? "owner-id",
    path: _91id_937IwTTQPD7TMeta?.path ?? "/owner/:id()",
    meta: _91id_937IwTTQPD7TMeta || {},
    alias: _91id_937IwTTQPD7TMeta?.alias || [],
    redirect: _91id_937IwTTQPD7TMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/owner/[id].vue").then(m => m.default || m)
  },
  {
    name: createEnGwZA49ZGMeta?.name ?? "payment-create",
    path: createEnGwZA49ZGMeta?.path ?? "/payment/create",
    meta: createEnGwZA49ZGMeta || {},
    alias: createEnGwZA49ZGMeta?.alias || [],
    redirect: createEnGwZA49ZGMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/payment/create.vue").then(m => m.default || m)
  },
  {
    name: history7i5eXbTFoTMeta?.name ?? "payment-history",
    path: history7i5eXbTFoTMeta?.path ?? "/payment/history",
    meta: history7i5eXbTFoTMeta || {},
    alias: history7i5eXbTFoTMeta?.alias || [],
    redirect: history7i5eXbTFoTMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/payment/history.vue").then(m => m.default || m)
  },
  {
    name: indextW5Be7sUH9Meta?.name ?? "payment",
    path: indextW5Be7sUH9Meta?.path ?? "/payment",
    meta: indextW5Be7sUH9Meta || {},
    alias: indextW5Be7sUH9Meta?.alias || [],
    redirect: indextW5Be7sUH9Meta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: addpaymentDXArqP5HBnMeta?.name ?? "plan-id-addpayment",
    path: addpaymentDXArqP5HBnMeta?.path ?? "/plan/:id()/addpayment",
    meta: addpaymentDXArqP5HBnMeta || {},
    alias: addpaymentDXArqP5HBnMeta?.alias || [],
    redirect: addpaymentDXArqP5HBnMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/addpayment.vue").then(m => m.default || m)
  },
  {
    name: completesW3S5GIfwgMeta?.name ?? "plan-id-cancel-complete",
    path: completesW3S5GIfwgMeta?.path ?? "/plan/:id()/cancel/complete",
    meta: completesW3S5GIfwgMeta || {},
    alias: completesW3S5GIfwgMeta?.alias || [],
    redirect: completesW3S5GIfwgMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/complete.vue").then(m => m.default || m)
  },
  {
    name: confirmZaJN544jSMMeta?.name ?? "plan-id-cancel-confirm",
    path: confirmZaJN544jSMMeta?.path ?? "/plan/:id()/cancel/confirm",
    meta: confirmZaJN544jSMMeta || {},
    alias: confirmZaJN544jSMMeta?.alias || [],
    redirect: confirmZaJN544jSMMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/confirm.vue").then(m => m.default || m)
  },
  {
    name: noticeocPvnygYG8Meta?.name ?? "plan-id-cancel-notice",
    path: noticeocPvnygYG8Meta?.path ?? "/plan/:id()/cancel/notice",
    meta: noticeocPvnygYG8Meta || {},
    alias: noticeocPvnygYG8Meta?.alias || [],
    redirect: noticeocPvnygYG8Meta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/notice.vue").then(m => m.default || m)
  },
  {
    name: reasonPgWqfryCjvMeta?.name ?? "plan-id-cancel-reason",
    path: reasonPgWqfryCjvMeta?.path ?? "/plan/:id()/cancel/reason",
    meta: reasonPgWqfryCjvMeta || {},
    alias: reasonPgWqfryCjvMeta?.alias || [],
    redirect: reasonPgWqfryCjvMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/reason.vue").then(m => m.default || m)
  },
  {
    name: starthlhSNoMOrIMeta?.name ?? "plan-id-cancel-start",
    path: starthlhSNoMOrIMeta?.path ?? "/plan/:id()/cancel/start",
    meta: starthlhSNoMOrIMeta || {},
    alias: starthlhSNoMOrIMeta?.alias || [],
    redirect: starthlhSNoMOrIMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/cancel/start.vue").then(m => m.default || m)
  },
  {
    name: indexjvPGDBbHRdMeta?.name ?? "plan-id-contract",
    path: indexjvPGDBbHRdMeta?.path ?? "/plan/:id()/contract",
    meta: indexjvPGDBbHRdMeta || {},
    alias: indexjvPGDBbHRdMeta?.alias || [],
    redirect: indexjvPGDBbHRdMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/contract/index.vue").then(m => m.default || m)
  },
  {
    name: indexh5wtssseCzMeta?.name ?? "plan-id-contract-payment",
    path: indexh5wtssseCzMeta?.path ?? "/plan/:id()/contract/payment",
    meta: indexh5wtssseCzMeta || {},
    alias: indexh5wtssseCzMeta?.alias || [],
    redirect: indexh5wtssseCzMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/contract/payment/index.vue").then(m => m.default || m)
  },
  {
    name: newZtC8agqjbyMeta?.name ?? "plan-id-contract-payment-new",
    path: newZtC8agqjbyMeta?.path ?? "/plan/:id()/contract/payment/new",
    meta: newZtC8agqjbyMeta || {},
    alias: newZtC8agqjbyMeta?.alias || [],
    redirect: newZtC8agqjbyMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/contract/payment/new.vue").then(m => m.default || m)
  },
  {
    name: completeGwgb7Zmwb2Meta?.name ?? "plan-id-distribute-complete",
    path: completeGwgb7Zmwb2Meta?.path ?? "/plan/:id()/distribute/complete",
    meta: completeGwgb7Zmwb2Meta || {},
    alias: completeGwgb7Zmwb2Meta?.alias || [],
    redirect: completeGwgb7Zmwb2Meta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/complete.vue").then(m => m.default || m)
  },
  {
    name: editC4Feea3JceMeta?.name ?? "plan-id-distribute-document-contentId-edit",
    path: editC4Feea3JceMeta?.path ?? "/plan/:id()/distribute/document/:contentId()/edit",
    meta: editC4Feea3JceMeta || {},
    alias: editC4Feea3JceMeta?.alias || [],
    redirect: editC4Feea3JceMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/document/[contentId]/edit.vue").then(m => m.default || m)
  },
  {
    name: createx4X1lzIP0gMeta?.name ?? "plan-id-distribute-document-create",
    path: createx4X1lzIP0gMeta?.path ?? "/plan/:id()/distribute/document/create",
    meta: createx4X1lzIP0gMeta || {},
    alias: createx4X1lzIP0gMeta?.alias || [],
    redirect: createx4X1lzIP0gMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/document/create.vue").then(m => m.default || m)
  },
  {
    name: edit2CVxn6OxfUMeta?.name ?? "plan-id-distribute-live-contentId-edit",
    path: edit2CVxn6OxfUMeta?.path ?? "/plan/:id()/distribute/live/:contentId()/edit",
    meta: edit2CVxn6OxfUMeta || {},
    alias: edit2CVxn6OxfUMeta?.alias || [],
    redirect: edit2CVxn6OxfUMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/live/[contentId]/edit.vue").then(m => m.default || m)
  },
  {
    name: createNM6LflVeKKMeta?.name ?? "plan-id-distribute-live-create",
    path: createNM6LflVeKKMeta?.path ?? "/plan/:id()/distribute/live/create",
    meta: createNM6LflVeKKMeta || {},
    alias: createNM6LflVeKKMeta?.alias || [],
    redirect: createNM6LflVeKKMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/live/create.vue").then(m => m.default || m)
  },
  {
    name: editJlKNPkn16VMeta?.name ?? "plan-id-distribute-livearchive-contentId-edit",
    path: editJlKNPkn16VMeta?.path ?? "/plan/:id()/distribute/livearchive/:contentId()/edit",
    meta: editJlKNPkn16VMeta || {},
    alias: editJlKNPkn16VMeta?.alias || [],
    redirect: editJlKNPkn16VMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/livearchive/[contentId]/edit.vue").then(m => m.default || m)
  },
  {
    name: editwtSXXROGNWMeta?.name ?? "plan-id-distribute-movie-contentId-edit",
    path: editwtSXXROGNWMeta?.path ?? "/plan/:id()/distribute/movie/:contentId()/edit",
    meta: editwtSXXROGNWMeta || {},
    alias: editwtSXXROGNWMeta?.alias || [],
    redirect: editwtSXXROGNWMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/movie/[contentId]/edit.vue").then(m => m.default || m)
  },
  {
    name: creates2qPeuWwYHMeta?.name ?? "plan-id-distribute-movie-create",
    path: creates2qPeuWwYHMeta?.path ?? "/plan/:id()/distribute/movie/create",
    meta: creates2qPeuWwYHMeta || {},
    alias: creates2qPeuWwYHMeta?.alias || [],
    redirect: creates2qPeuWwYHMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/movie/create.vue").then(m => m.default || m)
  },
  {
    name: historyma1XlulGeBMeta?.name ?? "plan-id-distribute-offer-campaignId-history",
    path: historyma1XlulGeBMeta?.path ?? "/plan/:id()/distribute/offer/:campaignId()/history",
    meta: historyma1XlulGeBMeta || {},
    alias: historyma1XlulGeBMeta?.alias || [],
    redirect: historyma1XlulGeBMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/offer/[campaignId]/history.vue").then(m => m.default || m)
  },
  {
    name: createKlJ8S3lejMMeta?.name ?? "plan-id-distribute-offer-create",
    path: createKlJ8S3lejMMeta?.path ?? "/plan/:id()/distribute/offer/create",
    meta: createKlJ8S3lejMMeta || {},
    alias: createKlJ8S3lejMMeta?.alias || [],
    redirect: createKlJ8S3lejMMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/offer/create.vue").then(m => m.default || m)
  },
  {
    name: edit5SIfklITIjMeta?.name ?? "plan-id-distribute-offer-edit",
    path: edit5SIfklITIjMeta?.path ?? "/plan/:id()/distribute/offer/edit",
    meta: edit5SIfklITIjMeta || {},
    alias: edit5SIfklITIjMeta?.alias || [],
    redirect: edit5SIfklITIjMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/distribute/offer/edit.vue").then(m => m.default || m)
  },
  {
    name: indexA6UVoPlMZzMeta?.name ?? "plan-id-document-contentId",
    path: indexA6UVoPlMZzMeta?.path ?? "/plan/:id()/document/:contentId()",
    meta: indexA6UVoPlMZzMeta || {},
    alias: indexA6UVoPlMZzMeta?.alias || [],
    redirect: indexA6UVoPlMZzMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/document/[contentId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWuTtb94UTfMeta?.name ?? "plan-id-edit",
    path: indexWuTtb94UTfMeta?.path ?? "/plan/:id()/edit",
    meta: indexWuTtb94UTfMeta || {},
    alias: indexWuTtb94UTfMeta?.alias || [],
    redirect: indexWuTtb94UTfMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexv97DdaNqmIMeta?.name ?? "plan-id",
    path: indexv97DdaNqmIMeta?.path ?? "/plan/:id()",
    meta: indexv97DdaNqmIMeta || {},
    alias: indexv97DdaNqmIMeta?.alias || [],
    redirect: indexv97DdaNqmIMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: addpaymentsq42VhWgW6Meta?.name ?? "plan-id-join-addpayment",
    path: addpaymentsq42VhWgW6Meta?.path ?? "/plan/:id()/join/addpayment",
    meta: addpaymentsq42VhWgW6Meta || {},
    alias: addpaymentsq42VhWgW6Meta?.alias || [],
    redirect: addpaymentsq42VhWgW6Meta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/addpayment.vue").then(m => m.default || m)
  },
  {
    name: agreementGFEsJTPCAHMeta?.name ?? "plan-id-join-agreement",
    path: agreementGFEsJTPCAHMeta?.path ?? "/plan/:id()/join/agreement",
    meta: agreementGFEsJTPCAHMeta || {},
    alias: agreementGFEsJTPCAHMeta?.alias || [],
    redirect: agreementGFEsJTPCAHMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/agreement.vue").then(m => m.default || m)
  },
  {
    name: authemailKy7LhKo6XiMeta?.name ?? "plan-id-join-authemail",
    path: authemailKy7LhKo6XiMeta?.path ?? "/plan/:id()/join/authemail",
    meta: authemailKy7LhKo6XiMeta || {},
    alias: authemailKy7LhKo6XiMeta?.alias || [],
    redirect: authemailKy7LhKo6XiMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/authemail.vue").then(m => m.default || m)
  },
  {
    name: complete0yCd5pXbWvMeta?.name ?? "plan-id-join-complete",
    path: complete0yCd5pXbWvMeta?.path ?? "/plan/:id()/join/complete",
    meta: complete0yCd5pXbWvMeta || {},
    alias: complete0yCd5pXbWvMeta?.alias || [],
    redirect: complete0yCd5pXbWvMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/complete.vue").then(m => m.default || m)
  },
  {
    name: confirmOHzc6ixkgiMeta?.name ?? "plan-id-join-confirm",
    path: confirmOHzc6ixkgiMeta?.path ?? "/plan/:id()/join/confirm",
    meta: confirmOHzc6ixkgiMeta || {},
    alias: confirmOHzc6ixkgiMeta?.alias || [],
    redirect: confirmOHzc6ixkgiMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/confirm.vue").then(m => m.default || m)
  },
  {
    name: documents9wlJZrMmxDMeta?.name ?? "plan-id-join-documents",
    path: documents9wlJZrMmxDMeta?.path ?? "/plan/:id()/join/documents",
    meta: documents9wlJZrMmxDMeta || {},
    alias: documents9wlJZrMmxDMeta?.alias || [],
    redirect: documents9wlJZrMmxDMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/documents.vue").then(m => m.default || m)
  },
  {
    name: payment_45bankDTelTLt7h2Meta?.name ?? "plan-id-join-payment-bank",
    path: payment_45bankDTelTLt7h2Meta?.path ?? "/plan/:id()/join/payment-bank",
    meta: payment_45bankDTelTLt7h2Meta || {},
    alias: payment_45bankDTelTLt7h2Meta?.alias || [],
    redirect: payment_45bankDTelTLt7h2Meta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/payment-bank.vue").then(m => m.default || m)
  },
  {
    name: payment_45cardzpYvXwfe8UMeta?.name ?? "plan-id-join-payment-card",
    path: payment_45cardzpYvXwfe8UMeta?.path ?? "/plan/:id()/join/payment-card",
    meta: payment_45cardzpYvXwfe8UMeta || {},
    alias: payment_45cardzpYvXwfe8UMeta?.alias || [],
    redirect: payment_45cardzpYvXwfe8UMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/payment-card.vue").then(m => m.default || m)
  },
  {
    name: payment_45methodWLUKCmDqo5Meta?.name ?? "plan-id-join-payment-method",
    path: payment_45methodWLUKCmDqo5Meta?.path ?? "/plan/:id()/join/payment-method",
    meta: payment_45methodWLUKCmDqo5Meta || {},
    alias: payment_45methodWLUKCmDqo5Meta?.alias || [],
    redirect: payment_45methodWLUKCmDqo5Meta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/payment-method.vue").then(m => m.default || m)
  },
  {
    name: personalinfo07Z9455hNFMeta?.name ?? "plan-id-join-personalinfo",
    path: personalinfo07Z9455hNFMeta?.path ?? "/plan/:id()/join/personalinfo",
    meta: personalinfo07Z9455hNFMeta || {},
    alias: personalinfo07Z9455hNFMeta?.alias || [],
    redirect: personalinfo07Z9455hNFMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/join/personalinfo.vue").then(m => m.default || m)
  },
  {
    name: createxCnAO2BpJXMeta?.name ?? "plan-id-library-create",
    path: createxCnAO2BpJXMeta?.path ?? "/plan/:id()/library/create",
    meta: createxCnAO2BpJXMeta || {},
    alias: createxCnAO2BpJXMeta?.alias || [],
    redirect: createxCnAO2BpJXMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/library/create.vue").then(m => m.default || m)
  },
  {
    name: edit4Muye1m9ZjMeta?.name ?? "plan-id-library-edit",
    path: edit4Muye1m9ZjMeta?.path ?? "/plan/:id()/library/edit",
    meta: edit4Muye1m9ZjMeta || {},
    alias: edit4Muye1m9ZjMeta?.alias || [],
    redirect: edit4Muye1m9ZjMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/library/edit.vue").then(m => m.default || m)
  },
  {
    name: hosts4BLQaSyvrMeta?.name ?? "plan-id-live-contentId-host",
    path: hosts4BLQaSyvrMeta?.path ?? "/plan/:id()/live/:contentId()/host",
    meta: hosts4BLQaSyvrMeta || {},
    alias: hosts4BLQaSyvrMeta?.alias || [],
    redirect: hosts4BLQaSyvrMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/live/[contentId]/host.vue").then(m => m.default || m)
  },
  {
    name: indexyqggVynpr9Meta?.name ?? "plan-id-live-contentId",
    path: indexyqggVynpr9Meta?.path ?? "/plan/:id()/live/:contentId()",
    meta: indexyqggVynpr9Meta || {},
    alias: indexyqggVynpr9Meta?.alias || [],
    redirect: indexyqggVynpr9Meta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/live/[contentId]/index.vue").then(m => m.default || m)
  },
  {
    name: result1CrO4LwJ8pMeta?.name ?? "plan-id-live-contentId-result",
    path: result1CrO4LwJ8pMeta?.path ?? "/plan/:id()/live/:contentId()/result",
    meta: result1CrO4LwJ8pMeta || {},
    alias: result1CrO4LwJ8pMeta?.alias || [],
    redirect: result1CrO4LwJ8pMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/live/[contentId]/result.vue").then(m => m.default || m)
  },
  {
    name: indexhE8evGGaLRMeta?.name ?? "plan-id-livearchive-contentId",
    path: indexhE8evGGaLRMeta?.path ?? "/plan/:id()/livearchive/:contentId()",
    meta: indexhE8evGGaLRMeta || {},
    alias: indexhE8evGGaLRMeta?.alias || [],
    redirect: indexhE8evGGaLRMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/livearchive/[contentId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexTj5ezZ4UOpMeta?.name ?? "plan-id-liveschedule-contentId",
    path: indexTj5ezZ4UOpMeta?.path ?? "/plan/:id()/liveschedule/:contentId()",
    meta: indexTj5ezZ4UOpMeta || {},
    alias: indexTj5ezZ4UOpMeta?.alias || [],
    redirect: indexTj5ezZ4UOpMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/liveschedule/[contentId]/index.vue").then(m => m.default || m)
  },
  {
    name: embedn4LBFd02fzMeta?.name ?? "plan-id-movie-contentId-embed",
    path: embedn4LBFd02fzMeta?.path ?? "/plan/:id()/movie/:contentId()/embed",
    meta: embedn4LBFd02fzMeta || {},
    alias: embedn4LBFd02fzMeta?.alias || [],
    redirect: embedn4LBFd02fzMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/movie/[contentId]/embed.vue").then(m => m.default || m)
  },
  {
    name: indexFBLyS66WD7Meta?.name ?? "plan-id-movie-contentId",
    path: indexFBLyS66WD7Meta?.path ?? "/plan/:id()/movie/:contentId()",
    meta: indexFBLyS66WD7Meta || {},
    alias: indexFBLyS66WD7Meta?.alias || [],
    redirect: indexFBLyS66WD7Meta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/movie/[contentId]/index.vue").then(m => m.default || m)
  },
  {
    name: paymentpPMmpJbGdkMeta?.name ?? "plan-id-payment",
    path: paymentpPMmpJbGdkMeta?.path ?? "/plan/:id()/payment",
    meta: paymentpPMmpJbGdkMeta || {},
    alias: paymentpPMmpJbGdkMeta?.alias || [],
    redirect: paymentpPMmpJbGdkMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/payment.vue").then(m => m.default || m)
  },
  {
    name: personalinfob1skzVSnDpMeta?.name ?? "plan-id-personalinfo",
    path: personalinfob1skzVSnDpMeta?.path ?? "/plan/:id()/personalinfo",
    meta: personalinfob1skzVSnDpMeta || {},
    alias: personalinfob1skzVSnDpMeta?.alias || [],
    redirect: personalinfob1skzVSnDpMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/personalinfo.vue").then(m => m.default || m)
  },
  {
    name: editkJCxeglNl1Meta?.name ?? "plan-id-price-priceId-edit",
    path: editkJCxeglNl1Meta?.path ?? "/plan/:id()/price/:priceId()/edit",
    meta: editkJCxeglNl1Meta || {},
    alias: editkJCxeglNl1Meta?.alias || [],
    redirect: editkJCxeglNl1Meta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/price/[priceId]/edit.vue").then(m => m.default || m)
  },
  {
    name: history19JuObZM8tMeta?.name ?? "plan-id-price-priceId-history",
    path: history19JuObZM8tMeta?.path ?? "/plan/:id()/price/:priceId()/history",
    meta: history19JuObZM8tMeta || {},
    alias: history19JuObZM8tMeta?.alias || [],
    redirect: history19JuObZM8tMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/price/[priceId]/history.vue").then(m => m.default || m)
  },
  {
    name: createOEcRhcoskGMeta?.name ?? "plan-id-price-create",
    path: createOEcRhcoskGMeta?.path ?? "/plan/:id()/price/create",
    meta: createOEcRhcoskGMeta || {},
    alias: createOEcRhcoskGMeta?.alias || [],
    redirect: createOEcRhcoskGMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/price/create.vue").then(m => m.default || m)
  },
  {
    name: completecmR7yrUJ5PMeta?.name ?? "plan-id-review-complete",
    path: completecmR7yrUJ5PMeta?.path ?? "/plan/:id()/review/complete",
    meta: completecmR7yrUJ5PMeta || {},
    alias: completecmR7yrUJ5PMeta?.alias || [],
    redirect: completecmR7yrUJ5PMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/review/complete.vue").then(m => m.default || m)
  },
  {
    name: indexCTMb6bIQ4TMeta?.name ?? "plan-id-review",
    path: indexCTMb6bIQ4TMeta?.path ?? "/plan/:id()/review",
    meta: indexCTMb6bIQ4TMeta || {},
    alias: indexCTMb6bIQ4TMeta?.alias || [],
    redirect: indexCTMb6bIQ4TMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/review/index.vue").then(m => m.default || m)
  },
  {
    name: index8GQbyMPh8PMeta?.name ?? "plan-id-reviews",
    path: index8GQbyMPh8PMeta?.path ?? "/plan/:id()/reviews",
    meta: index8GQbyMPh8PMeta || {},
    alias: index8GQbyMPh8PMeta?.alias || [],
    redirect: index8GQbyMPh8PMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: editSscvfP2vQ3Meta?.name ?? "plan-id-talk-talkRoomId-edit",
    path: editSscvfP2vQ3Meta?.path ?? "/plan/:id()/talk/:talkRoomId()/edit",
    meta: editSscvfP2vQ3Meta || {},
    alias: editSscvfP2vQ3Meta?.alias || [],
    redirect: editSscvfP2vQ3Meta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/talk/[talkRoomId]/edit.vue").then(m => m.default || m)
  },
  {
    name: editdm0vlkjdo2KyMeta?.name ?? "plan-id-talk-talkRoomId-editdm",
    path: editdm0vlkjdo2KyMeta?.path ?? "/plan/:id()/talk/:talkRoomId()/editdm",
    meta: editdm0vlkjdo2KyMeta || {},
    alias: editdm0vlkjdo2KyMeta?.alias || [],
    redirect: editdm0vlkjdo2KyMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/talk/[talkRoomId]/editdm.vue").then(m => m.default || m)
  },
  {
    name: create93acIT57TkMeta?.name ?? "plan-id-talk-create",
    path: create93acIT57TkMeta?.path ?? "/plan/:id()/talk/create",
    meta: create93acIT57TkMeta || {},
    alias: create93acIT57TkMeta?.alias || [],
    redirect: create93acIT57TkMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plan/[id]/talk/create.vue").then(m => m.default || m)
  },
  {
    name: joiningLi8xx6I92EMeta?.name ?? "plans-joining",
    path: joiningLi8xx6I92EMeta?.path ?? "/plans/joining",
    meta: joiningLi8xx6I92EMeta || {},
    alias: joiningLi8xx6I92EMeta?.alias || [],
    redirect: joiningLi8xx6I92EMeta?.redirect,
    component: () => import("/home/runner/work/fincs-webfront/fincs-webfront/src/pages/plans/joining.vue").then(m => m.default || m)
  }
]