import { Mutex, tryAcquire, E_ALREADY_LOCKED } from "async-mutex";
import { defineStore } from "pinia";
import { LoginInfo } from "~/entities/LoginInfo";
import type { ILoginRepository } from "~/interfaces/ILoginRepository";
import { LoginRepository } from "~/Repositories/LoginRepository";

export const useLoginStore = defineStore(
  "LoginStore",
  () => {
    // 他の store 同様に inject したいが、この LoginStore は middleware/auth から呼ばれ、
    // かつ Nuxt 3 では inject は setup コンテキスト中でしか使えず middleware では使えない。
    // よって、やむを得ず直接インスタンスを生成する
    const repository: ILoginRepository = new LoginRepository();

    const loginInfo = ref<LoginInfo>(new LoginInfo());

    const deviceToken = ref<string | null>(null);
    const deviceTokenSentDate = ref<number | null>(null);

    /**
     * GTMで使用するユーザーIDをCookieにセットする
     */
    const setCookieForGTM = async () => {
      let needReload = false;
      // GTMで使用するユーザーID用Cookie。maxAgeはsafariで設定できる最大値の7日としておく
      const cookieUserId = useCookie("fincs_userid", { maxAge: 604800 });

      if (loginInfo.value?.userId && !cookieUserId.value) {
        needReload = true;
      }
      // maxAgeを再設定したいので一度nullにして削除する
      cookieUserId.value = null;
      // 削除後に再設定するときに値が反映されないことがあるので、少し待ってから再設定する
      await new Promise(resolve => setTimeout(resolve, 10));
      if (loginInfo.value?.userId) {
        cookieUserId.value = loginInfo.value?.userId;
      }
      return needReload;
    };

    const login = async (idToken: string) => {
      loginInfo.value = await repository.login(idToken);
      deviceToken.value = null;
      await setCookieForGTM();
    };

    const register = async (idToken: string) => {
      loginInfo.value = await repository.register(idToken);
      await setCookieForGTM();
    };

    const adminLogin = async (idToken: string) => {
      loginInfo.value = await repository.adminLogin(idToken);
      deviceToken.value = null;
      await setCookieForGTM();
    };

    const refreshMutex = new Mutex();
    const refresh = async () => {
      // リフレッシュトークンを使ってセッショントークンを更新する
      // 非同期で複数リクエスト発生する場合があるので、排他制御で最初の要求のみを処理し、以降は待機させる
      try {
        await tryAcquire(refreshMutex).runExclusive(async () => {
          const info = await repository.refresh(loginInfo.value.refreshToken);
          loginInfo.value.sessionToken = info.sessionToken;
          loginInfo.value.refreshToken = info.refreshToken;
          await setCookieForGTM();
        });
      } catch (e) {
        if (e === E_ALREADY_LOCKED) {
          await refreshMutex.waitForUnlock();
        } else {
          throw e;
        }
      }
    };

    const setLoginInfo = (newLoginInfo: LoginInfo): void => {
      loginInfo.value = newLoginInfo;
      setCookieForGTM();
    };

    const setDeviceToken = (token: string | null): void => {
      deviceToken.value = token;
      deviceTokenSentDate.value = new Date().getTime();
    };

    const shouldSendDeviceToken = (token: string): boolean => {
      if (deviceToken.value !== token) {
        return true;
      }
      if (!deviceTokenSentDate.value) {
        return true;
      }
      const now = new Date().getTime();
      // 12時間以上経過していたら再送信
      return now - deviceTokenSentDate.value > 12 * 60 * 60 * 1000;
    };

    const logout = async () => {
      loginInfo.value = new LoginInfo();
      deviceToken.value = null;
      await setCookieForGTM();
    };

    /**
     * ログイン済みかどうか
     */
    const isLogin = (): boolean => {
      return loginInfo.value?.userId?.length > 0;
    };

    /**
     * 配信者かどうか
     */
    const isOwner = (): boolean => {
      return loginInfo.value?.userRoleCode === "owner";
    };

    /**
     * 管理者かどうか
     */
    const isAdmin = (): boolean => {
      return loginInfo.value?.userRoleCode === "administrator";
    };

    return {
      loginInfo,
      deviceToken,
      deviceTokenSentDate,
      setCookieForGTM,
      login,
      register,
      adminLogin,
      refresh,
      setLoginInfo,
      setDeviceToken,
      shouldSendDeviceToken,
      logout,
      isLogin,
      isOwner,
      isAdmin,
    };
  },
  {
    persist: {
      storage: persistedState.localStorage,
    },
  },
);
