import _ from "lodash";
import { defineStore } from "pinia";
import { TalkRoom } from "~/entities/TalkRoom";
import { Talk } from "~/entities/Talk";
import { TalkOgp } from "~/entities/TalkOgp";
import { TalkRoomUser } from "~/entities/TalkRoomUser";
import { ITalkRoomRepositoryFactoryKey, injectRepository } from "~/Repositories/provide";
import type { ITalkRoomRepository } from "~/interfaces/ITalkRoomRepository";
import { OgpRepository } from "~/Repositories/OgpRepository";
import { useLoginStore } from "~/stores/LoginStore";
import { usePlanStore } from "~/stores/PlanStore";
import { useErrorStore } from "~/stores/ErrorStore";

/**
 * トークルームデータを扱うクラス
 * getTalkでユーザーデータを利用するので、トークルーム変更時には最初にgetUsersを呼ぶこと。
 */
export const useTalkRoomStore = defineStore("TalkRoomStore", () => {
  const talkRooms = ref<TalkRoom[]>([]);
  const talkRoomsForSetting = ref<TalkRoom[]>([]); // 通知設定用のトークルームリストを別でもつ
  const observeTalkRooms = ref<{ id: string; latesteTalkID: number }[]>([]);
  const selectedPlanId = ref<string>("");
  const selectedTalkRoomId = ref<string>("");
  const talkRoomSetDone = ref<boolean>(false);
  const talks = ref<Talk[]>([]);
  const prefetchedTalksBefore = ref<Talk[]>([]);
  const prefetchedTalksAfter = ref<Talk[]>([]);
  const replyTalks = ref<Talk[]>([]); // 返信用のトークのキャッシュ。talksに含まれていない場合はこちらから取得する
  const talkRoomUsers = ref<{ [id: string]: TalkRoomUser }>({});
  const userGoodList = ref<{ [id: string]: string[] }>({});
  const hasPrevTalk = ref<boolean>(false);
  const hasNextTalk = ref<boolean>(false);
  const loading = ref(false);
  const updating = ref(false);
  const updateTalk = ref(false); // talkのlengthは監視できるが中身の変更は監視できないのでフラグを立てる

  const repository: ITalkRoomRepository = injectRepository(ITalkRoomRepositoryFactoryKey);
  const ogpRepository = new OgpRepository();

  const loginStore = useLoginStore();
  const planStore = usePlanStore();
  const errorStore = useErrorStore();

  // 未読フラグを設定する
  const setUnreadFlag = (talkRoomId: string, unreadFlag: boolean) => {
    const talkRoom = talkRooms.value.find(item => item.talkRoomId === talkRoomId);
    if (!talkRoom || !planStore.plan) {
      return;
    }
    // トークルームの未読フラグを設定する
    talkRoom.hasUnread = unreadFlag;
    // 講座ページのトークタブの未読フラグも設定する
    // 非公開トークルーム判定対象外 https://arkad-co.slack.com/archives/C06P8P9L95G/p1715836390713039?thread_ts=1715745606.940479&cid=C06P8P9L95G
    // アーカイブトークルーム判定対象外 https://arkad-co.slack.com/archives/C06P8P9L95G/p1716195045836929?thread_ts=1716194246.632289&cid=C06P8P9L95G
    const hasUnread = talkRooms.value.findIndex(item => item.hasUnread && item.published && !item.isArchive) !== -1;
    planStore.plan.hasUnreadTalk = hasUnread;
  };

  // トークルームのデータ監視を行う。ここではlatesteTalkIDの監視のみでトークデータの監視はしない。
  const startTalkRoomsObserve = (planId: string) => {
    loading.value = true;
    selectedPlanId.value = planId;
    observeTalkRooms.value = [];
    try {
      repository.startTalkRoomsObserve(
        planId,
        (observeTalkRoom: { id: string; latesteTalkID: number }) => {
          // 最初にデータを入れる
          observeTalkRooms.value.push(observeTalkRoom);
        },
        (observeTalkRoom: { id: string; latesteTalkID: number }) => {
          const observeRoom = observeTalkRooms.value.find(item => item.id === observeTalkRoom.id);
          if (!observeRoom) {
            return;
          }
          if (observeRoom.latesteTalkID !== observeTalkRoom.latesteTalkID) {
            // latesteTalkIDに違いがあったら未読フラグを立てる
            observeRoom.latesteTalkID = observeTalkRoom.latesteTalkID;
            if (observeRoom.id !== selectedTalkRoomId.value) {
              setUnreadFlag(observeRoom.id, true);
            }
          }
        },
      );
    } finally {
      loading.value = false;
    }
  };

  const stopTalkRoomsObserve = () => {
    loading.value = true;
    try {
      repository.stopTalkRoomsObserve();
    } finally {
      loading.value = false;
      clearTalkRoom();
    }
  };

  const clearTalkRoom = () => {
    talkRooms.value = [];
  };

  const startUsersObserve = (planId: string) => {
    repository.startUsersObserve(planId, addUsersCallback, changeUsersCallback);
  };

  const stopUsersObserve = () => {
    repository.stopUsersObserve();
  };

  // トークデータの監視を行う
  const startTalksObserve = (
    planId: string,
    talkRoomId: string,
    includeAdd: boolean,
    addAfterTalkId: string | undefined,
    changeFromTalkId: string | undefined,
    changeToTalkId: string | undefined,
  ) => {
    loading.value = true;
    try {
      repository.startTalksObserve(
        planId,
        talkRoomId,
        includeAdd ? addCallback : undefined,
        addAfterTalkId,
        changeCallback,
        changeFromTalkId,
        changeToTalkId,
        () => {
          talkRoomSetDone.value = true;
        },
      );
    } finally {
      loading.value = false;
    }
  };

  const stopTalksObserve = () => {
    repository.stopTalksObserve();
  };

  // トークルームにてユーザーのいいね済みリスト監視を行う
  const startUserGoodsObserve = async (talkRoomId: string) => {
    if (!loginStore.isLogin()) return; // ログインしていない場合は何もしない
    try {
      await repository.startUserGoodsObserve(loginStore.loginInfo!.userId, talkRoomId, (type: string, id: string) => {
        if (!userGoodList.value[talkRoomId]) {
          userGoodList.value[talkRoomId] = [];
        }
        if (type === "added") {
          userGoodList.value[talkRoomId].push(id);
        } else if (type === "removed") {
          userGoodList.value[talkRoomId] = userGoodList.value[talkRoomId].filter(item => item !== id);
        }
      });
    } finally {
      /* empty */
    }
  };

  const stopUserGoodsObserve = () => {
    repository.stopUserGoodsObserve();
  };

  const initTalkRoom = (planId: string, talkRoomId: string) => {
    selectedPlanId.value = planId;
    selectedTalkRoomId.value = talkRoomId;
    talkRoomSetDone.value = false;
    hasPrevTalk.value = false;
    hasNextTalk.value = false;
    talks.value = [];
    prefetchedTalksBefore.value = [];
    prefetchedTalksAfter.value = [];
    replyTalks.value = [];
  };

  const setUpdateTalkFlag = () => {
    updateTalk.value = true;
  };

  const clearUpdateTalkFlag = () => {
    updateTalk.value = false;
  };

  // api
  const getTalkRooms = async (planId: string) => {
    try {
      talkRooms.value = await repository.getTalkRooms(planId);
    } catch (e) {
      sentryErrorLog(`TalkRoomStore.getTalkRooms: ${e}`);
      errorStore.showConnectionError();
    }
  };

  const getTalkRoomsForSetting = async (planId: string) => {
    try {
      talkRoomsForSetting.value = await repository.getTalkRooms(planId);
    } catch (e) {
      sentryErrorLog(`TalkRoomStore.getTalkRoomsForSetting: ${e}`);
      errorStore.showConnectionError();
    }
  };

  const searchTalkIds = async (talkRoomId: string, searchWord: string) => {
    const res = await repository.searchTalksFromApi(talkRoomId, searchWord);
    return res.map(item => item.talkId);
  };

  const getGoodUsers = async (talkId: string, page: number) => {
    return await repository.getGoodUsersFromApi(talkId, page);
  };

  const updateTalkRoomUsers = async (planId: string, userIds: string[]) => {
    const userIdsToQuery = [];
    for (const userId of userIds) {
      if (!(userId in talkRoomUsers.value)) {
        userIdsToQuery.push(userId);
      }
    }
    if (userIdsToQuery.length > 0) {
      const rs = await repository.getUsers(planId, userIdsToQuery);
      for (const rs1 of rs) {
        talkRoomUsers.value[rs1.userId] = rs1;
      }
    }
  };

  const updateTalks = async (planId: string, newTalks: Talk[]) => {
    await updateTalkRoomUsers(
      planId,
      newTalks.map(t => t.userId),
    );
    talks.value = newTalks;
  };

  const addOneTalk = async (planId: string, newTalk: Talk) => {
    await updateTalkRoomUsers(planId, [newTalk.userId]);
    talks.value.push(newTalk);
  };

  const updateOneTalk = async (planId: string, newTalk: Talk) => {
    await updateTalkRoomUsers(planId, [newTalk.userId]);
    const oldIndex = talks.value.findIndex(item => item.talkId === newTalk.talkId);
    talks.value[oldIndex] = newTalk;
  };

  const getTalksBefore = async (
    planId: string,
    talkRoomId: string,
    talkId: string | undefined,
    pageSize = 20,
    useCacheIfAvailable: boolean,
  ) => {
    let res = null;
    if (prefetchedTalksBefore.value.length > 0) {
      // prefetch されたデータがあればそれを使う
      res = prefetchedTalksBefore.value;
      prefetchedTalksBefore.value = [];
    } else {
      // prefetch されたデータがなければここで今回分をfetch
      res = await repository.getTalks(planId, talkRoomId, talkId, true, false, pageSize, useCacheIfAvailable);
    }

    // 次回分を非同期で prefetch
    if (res.length > 0) {
      repository
        .getTalks(planId, talkRoomId, res.at(0)!.talkId, true, false, pageSize, useCacheIfAvailable)
        .then(res => {
          prefetchedTalksBefore.value = res;
        });
    }

    // トークリストに連結
    await updateTalks(planId, res.concat(talks.value));
    // 追加のトークが存在するかどうか (論理削除分は無視)
    hasPrevTalk.value = res.filter(t => !t.deleteType).length > 0;

    // トーク情報を付与する
    setTalkInfoFromIdList(
      res.map((item: Talk) => {
        return item.talkId;
      }),
    );
  };

  const getTalksAfter = async (
    planId: string,
    talkRoomId: string,
    talkId: string | undefined,
    pageSize = 20,
    useCacheIfAvailable: boolean,
  ) => {
    let res = null;
    if (prefetchedTalksAfter.value.length > 0) {
      // prefetch されたデータがあればそれを使う
      res = prefetchedTalksAfter.value;
      prefetchedTalksAfter.value = [];
    } else {
      // prefetch されたデータがなければここで今回分をfetch
      res = await repository.getTalks(planId, talkRoomId, talkId, false, false, pageSize, useCacheIfAvailable);
    }

    // 次回分を非同期で prefetch
    if (res.length > 0) {
      repository
        .getTalks(planId, talkRoomId, res.at(-1)!.talkId, false, false, pageSize, useCacheIfAvailable)
        .then(res => {
          prefetchedTalksAfter.value = res;
        });
    }

    // トークリストに連結
    await updateTalks(planId, talks.value.concat(res));
    // 追加のトークが存在するかどうか (論理削除分は無視)
    hasNextTalk.value = res.filter(t => !t.deleteType).length > 0;

    // トーク情報を付与する
    setTalkInfoFromIdList(
      res.map((item: Talk) => {
        return item.talkId;
      }),
    );
  };

  const getTalksAt = async (
    planId: string,
    talkRoomId: string,
    talkId: string,
    pageSize: number,
    useCacheIfAvailable: boolean,
  ) => {
    // 最初に初期化
    initTalkRoom(planId, talkRoomId);

    // talkId 前後のトークを取得して新規に talks へ設定
    const res1 = await repository.getTalks(
      planId,
      talkRoomId,
      talkId,
      true,
      true,
      pageSize / 2 + 1,
      useCacheIfAvailable,
    );
    const res2 = await repository.getTalks(planId, talkRoomId, talkId, false, false, pageSize / 2, useCacheIfAvailable);

    // トークリストを連結
    const res = res1.concat(res2);
    await updateTalks(planId, res);
    // 追加のトークが存在するかどうか (論理削除分は無視)
    hasPrevTalk.value = res.filter(t => !t.deleteType).length > 0;
    hasNextTalk.value = res.filter(t => !t.deleteType).length > 0;

    // トーク情報を付与する
    setTalkInfoFromIdList(
      res.map((item: Talk) => {
        return item.talkId;
      }),
    );
  };

  // 既読設定
  const setLastRead = async (userId: string, talkRoomId: string, talkId: string) => {
    const result = await repository.setLastRead(userId, talkRoomId, talkId);
    // 未読から既読へ変更された場合、内部データも既読にする
    if (result) {
      setUnreadFlag(talkRoomId, false);
    }
  };

  const sendTalk = async (planId: string, talkRoomId: string, message: string, replyTalkId: string | undefined) => {
    await repository.sendTalk(planId, talkRoomId, message, replyTalkId);
  };

  const sendFile = async (talkRoomId: string, file: File, replyTalkId: string | undefined) => {
    await repository.sendFile(talkRoomId, file, replyTalkId);
  };

  const deleteTalk = async (talkId: string) => {
    await repository.deleteTalk(talkId);
  };

  const sendGood = async (planId: string, talkRoomId: string, talkId: string, userId: string) => {
    await repository.sendGood(planId, talkRoomId, talkId, userId);
  };

  const deleteGood = async (planId: string, talkRoomId: string, talkId: string, userId: string) => {
    await repository.deleteGood(planId, talkRoomId, talkId, userId);
  };

  // トークのコンテンツURLが期限切れであれば再取得する処理
  const checkContentsUrl = async (talk: Talk): Promise<string | undefined> => {
    try {
      const result = CheckS3Expire(talk.contentsUrl);
      if (result) {
        return talk.contentsUrl;
      }
      // 無効なURLな場合はURLを再取得する
      const res = await repository.getTalkFromApi(selectedTalkRoomId.value, talk.talkId);
      if (res) {
        talk.contentsUrl = res.contents;
        return res.contents;
      }
    } catch (e) {
      console.log(e);
    }
    return undefined;
  };

  // 以下private

  const addCallback = async (talk: Talk) => {
    if (!talkRoomSetDone.value) return;

    // 検索結果移動や返信元へのジャンプ後など、最新メッセージを保持していない場合は追加しない
    // (でないと途中に挿入され不正な状態となってしまう)
    if (hasNextTalk.value) {
      return;
    }

    // トークリストにpushする
    await addOneTalk(selectedPlanId.value, talk);
    // トーク情報を付与する。全部awaitで待つと遅いので並列処理とする
    setTalkInfoFromIdList([talk.talkId]);
  };

  const changeCallback = async (talk: Talk) => {
    if (!talkRoomSetDone.value) return;
    const oldIndex = talks.value.findIndex(item => item.talkId === talk.talkId);
    if (oldIndex !== -1) {
      // トークリストの中身を入れ替える
      await updateOneTalk(selectedPlanId.value, talk);
      // トーク情報を付与する。全部awaitで待つと遅いので並列処理とする
      setTalkInfoFromIdList([talk.talkId]);
    }
    // 返信元に変更があった場合
    const oldReplyIndex = talks.value.findIndex(item => item.replyTalkId === talk.talkId);
    if (oldReplyIndex !== -1) {
      // 返信元トークを更新 (OGPや返信は紐づかないので、素のデータのみ設定)
      talks.value[oldReplyIndex].replyTalk = talk;
    }
  };

  const addUsersCallback = (user: TalkRoomUser) => {
    if (!talkRoomSetDone.value) return;
    talkRoomUsers.value[user.userId] = user;
    setUpdateTalkFlag();
  };

  const changeUsersCallback = (user: TalkRoomUser) => {
    if (!talkRoomSetDone.value) return;
    talkRoomUsers.value[user.userId] = user;
    setUpdateTalkFlag();
  };

  // talkにいいね情報、OGP情報を付与する
  const setTalkInfoFromIdList = (talkIdList: string[]) => {
    // 返信情報を付与する
    setReplyInfoFromIdList(talkIdList);
    // OGP情報を付与する
    setTalkOGPInfoFromIdList(talkIdList);

    setUpdateTalkFlag();
  };

  // talkにOGP情報を付与する
  const setTalkOGPInfoFromIdList = (talkIdList: string[]) => {
    try {
      for (const talkId of talkIdList) {
        const talk = talks.value.find(item => item.talkId === talkId);
        if (talk) {
          setTalkOGPInfo(talk).then(result => {
            if (result) setUpdateTalkFlag();
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  // talkに返信情報を付与する
  const setReplyInfoFromIdList = (talkIdList: string[]) => {
    try {
      for (const talkId of talkIdList) {
        const talk = talks.value.find(item => item.talkId === talkId);
        if (talk) {
          if (setReplyInfo(talk)) {
            setUpdateTalkFlag();
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  // talkにOGP情報を付与する (talk単位)
  const setTalkOGPInfo = async (talk: Talk) => {
    if (talk.messageType !== "text") return false;
    const regexpUrl = /(https?|ftp):\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#\u3001-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g; // eslint-disable-line
    const urlAllMatches = talk.contents.match(regexpUrl);
    talk.ogpList = [];
    if (urlAllMatches) {
      for (let i = 0; i < urlAllMatches.length; i++) {
        await setTalkOGPInfoByUrl(talk, urlAllMatches[i]);
      }
      return true;
    }
    return false;
  };

  // talkにOGP情報を付与する (talk内の各url単位)
  const ogpRequestCached = new Map(); // URLごとのブラウザキャッシュ有無
  const ogpRequestTimers = new Map(); // トークxURLごとのデバウンス用タイマー
  const setTalkOGPInfoByUrl = async (talk: Talk, url: string) => {
    // まず API から OGP を取得する関数を定義
    const getAndSetOgpToTalk = async (talk: Talk, url: string) => {
      try {
        const ogpLists = await ogpRepository.getOgp(url);
        // OGP取れなかったら作らない
        if (ogpLists?.length) {
          const ogp = new TalkOgp("", "", "", url);
          ogp.title = _.find(ogpLists, ["property", "og:title"])?.content;
          ogp.description = _.find(ogpLists, ["property", "og:description"])?.content;
          ogp.image = _.find(ogpLists, ["property", "og:image"])?.content;
          talk.ogpList.push(ogp);
        }
        // ブラウザキャッシュ済みであることを記録
        ogpRequestCached.set(url, true);
      } catch (_e) {
        // ogp取得APIエラー
      }
    };

    const cachedOgp = ogpRequestCached.get(url);
    if (cachedOgp) {
      // OGP URL がブラウザキャッシュにあれば即時取得
      await getAndSetOgpToTalk(talk, url);
    } else {
      // OGP URL がブラウザキャッシュになければデバウンスでバースト抑止
      // 200msタイマーとしたのは、OGP取得リクエストが概ね100ms前後なので余裕を持ちつつ、表示が遅くなりすぎない値として選定
      const key = `${talk.talkId}-${url}`;
      let timer = ogpRequestTimers.get(key);
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        getAndSetOgpToTalk(talk, url);
      }, 200);
      ogpRequestTimers.set(key, timer);
    }
  };

  // talkに返信情報を付与する
  const replyRequestTimers = new Map(); // トークごとのデバウンス用タイマー
  const setReplyInfo = (talk: Talk) => {
    if (!talk.replyTalkId) return false;

    // まず自分のトークリストから探す
    let replyTalk = talks.value.find(item => item.talkId === talk.replyTalkId);
    if (replyTalk) {
      talk.replyTalk = replyTalk;
      return true;
    }

    // 自分のトークリストになければキャッシュリストから探す
    replyTalk = replyTalks.value.find(item => item.talkId === talk.replyTalkId);
    if (replyTalk) {
      talk.replyTalk = replyTalk;
    } else {
      // キャッシュリストにもなければFirestoreから取得しキャッシュに追加する
      // デバウンスでバースト抑止
      // 200msタイマーとしたのは、トーク取得リクエストが概ね100ms前後なので余裕を持ちつつ、表示が遅くなりすぎない値として選定
      const key = talk.talkId;
      let timer = replyRequestTimers.get(key);
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(async () => {
        const res = await repository.getTalk(selectedPlanId.value, selectedTalkRoomId.value, talk.replyTalkId!);
        if (res) {
          talk.replyTalk = res;
          replyTalks.value.push(res);
        }
      }, 200);
      replyRequestTimers.set(key, timer);
    }
    return true;
  };

  return {
    talkRooms,
    talkRoomsForSetting,
    talks,
    talkRoomSetDone,
    hasPrevTalk,
    hasNextTalk,
    loading,
    updating,
    updateTalk,
    talkRoomUsers,
    userGoodList,
    startTalkRoomsObserve,
    stopTalkRoomsObserve,
    startUsersObserve,
    stopUsersObserve,
    startTalksObserve,
    stopTalksObserve,
    getTalkRooms,
    getTalkRoomsForSetting,
    getGoodUsers,
    searchTalkIds,
    getTalksBefore,
    getTalksAfter,
    getTalksAt,
    setLastRead,
    sendTalk,
    sendFile,
    initTalkRoom,
    deleteTalk,
    sendGood,
    deleteGood,
    clearUpdateTalkFlag,
    checkContentsUrl,

    startUserGoodsObserve,
    stopUserGoodsObserve,
  };
});
