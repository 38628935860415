// repository 実装を provide する (各 store に inject される想定)
import { type App, inject } from "vue";

import { ConversionRepository } from "./ConversionRepository";
import { ContentsRepository } from "~/Repositories/ContentsRepository";
import { type IContentsRepository } from "~/interfaces/IContentsRepository";
import { ContractMemberRepository } from "~/Repositories/ContractMemberRepository";
import { type IContractMemberRepository } from "~/interfaces/IContractMemberRepository";
import { DeviceRepository } from "~/Repositories/DeviceRepository";
import { type IDeviceRepository } from "~/interfaces/IDeviceRepository";
import { DistributionRepository } from "~/Repositories/DistributionRepository";
import { type IDistributionRepository } from "~/interfaces/IDistributionRepository";
import { LineLinkRepository } from "~/Repositories/LineLinkRepository";
import { type ILineLinkRepository } from "~/interfaces/ILineLinkRepository";
import { LineLinkStatusRepository } from "~/Repositories/LineLinkStatusRepository";
import { type ILineLinkStatusRepository } from "~/interfaces/ILineLinkStatusRepository";
import { LiveChatRepository } from "~/Repositories/LiveChatRepository";
import { type ILiveChatRepository } from "~/interfaces/ILiveChatRepository";
import { LiveRepository } from "~/Repositories/LiveRepository";
import { type ILiveRepository } from "~/interfaces/ILiveRepository";
import { LiveStatusRepository } from "~/Repositories/LiveStatusRepository";
import { type ILiveStatusRepository } from "~/interfaces/ILiveStatusRepository";
import { LoginRepository } from "~/Repositories/LoginRepository";
import { type ILoginRepository } from "~/interfaces/ILoginRepository";
import { OgpRepository } from "~/Repositories/OgpRepository";
import { type IOgpRepository } from "~/interfaces/IOgpRepository";
import { OwnerRepository } from "~/Repositories/OwnerRepository";
import { type IOwnerRepository } from "~/interfaces/IOwnerRepository";
import { PaymentHistoryRepository } from "~/Repositories/PaymentHistoryRepository";
import { type IPaymentHistoryRepository } from "~/interfaces/IPaymentHistoryRepository";
import { PaymentRepository } from "~/Repositories/PaymentRepository";
import { type IPaymentRepository } from "~/interfaces/IPaymentRepository";
import { PersonalInfoRepository } from "~/Repositories/PersonalInfoRepository";
import { type IPersonalInfoRepository } from "~/interfaces/IPersonalInfoRepository";
import { PlanRepository } from "~/Repositories/PlanRepository";
import { type IPlanRepository } from "~/interfaces/IPlanRepository";
import { TagRepository } from "~/Repositories/TagRepository";
import { type ITagRepository } from "~/interfaces/ITagRepository";
import { TalkRoomRepository } from "~/Repositories/TalkRoomRepository";
import { type ITalkRoomRepository } from "~/interfaces/ITalkRoomRepository";
import { LibraryRepository } from "~/Repositories/LibraryRepository";
import { type ILibraryRepository } from "~/interfaces/ILibraryRepository";
import { TalkRoomEditRepository } from "~/Repositories/TalkRoomEditRepository";
import { type ITalkRoomEditRepository } from "~/interfaces/ITalkRoomEditRepository";
import { UserRepository } from "~/Repositories/UserRepository";
import { type IUserRepository } from "~/interfaces/IUserRepository";
import { PushNotificationRepository } from "~/Repositories/PushNotificationRepository";
import { type IPushNotificationRepository } from "~/interfaces/IPushNotificationRepository";
import { NotificationSettingRepository } from "~/Repositories/NotificationSettingRepository";
import { type INotificationSettingRepository } from "~/interfaces/INotificationSettingRepository";
import { AdminToolRepository } from "~/Repositories/AdminToolRepository";
import { type IAdminToolRepository } from "~/interfaces/IAdminToolRepository";
import type { IConversionRepository } from "~/interfaces/IConversionRepository";
import type { IMasterRepository } from "~/interfaces/IMasterRepository";
import { MasterRepository } from "~/Repositories/MasterRepository";
import type { IPlanPriceChangeReservationRepository } from "~/interfaces/IPlanPriceChangeReservationRepository";
import { PlanPriceChangeReservationRepository } from "~/Repositories/PlanPriceChangeReservationRepository";
import type { ICampaignRepository } from "~/interfaces/ICampaignRepository";
import { CampaignRepository } from "~/Repositories/CampaignRepository";
import type { IPlanManageRepository } from "~/interfaces/IPlanManageRepository";
import { PlanManageRepository } from "~/Repositories/PlanManageRepository";
import type { IPlanPriceManageRepository } from "~/interfaces/IPlanPriceManageRepository";
import { PlanPriceManageRepository } from "~/Repositories/PlanPriceManageRepository";

import { PlanPriceRepository } from "~/Repositories/PlanPriceRepository";
import { type IPlanPriceRepository } from "~/interfaces/IPlanPriceRepository";

// repository を inject するためのヘルパー関数
export const injectRepository = (key: Symbol) => {
  return inject<() => any>(key)!();
};

// provide/inject のためのキーを定義
export const IContentsRepositoryFactoryKey = Symbol("IContentsRepositoryFactory");
export const IContractMemberRepositoryFactoryKey = Symbol("IContractMemberRepositoryFactory");
export const IDashboardRepositoryFactoryKey = Symbol("IDashboardRepositoryFactory");
export const IDeviceRepositoryFactoryKey = Symbol("IDeviceRepositoryFactory");
export const IDistributionRepositoryFactoryKey = Symbol("IDistributionRepositoryFactory");
export const ILineLinkRepositoryFactoryKey = Symbol("ILineLinkRepositoryFactory");
export const ILineLinkStatusRepositoryFactoryKey = Symbol("ILineLinkStatusRepositoryFactory");
export const ILiveChatRepositoryFactoryKey = Symbol("ILiveChatRepositoryFactory");
export const ILiveRepositoryFactoryKey = Symbol("ILiveRepositoryFactory");
export const ILiveStatusRepositoryFactoryKey = Symbol("ILiveStatusRepositoryFactory");
export const ILoginRepositoryFactoryKey = Symbol("ILoginRepositoryFactory");
export const IOgpRepositoryFactoryKey = Symbol("IOgpRepositoryFactory");
export const IOwnerRepositoryFactoryKey = Symbol("IOwnerRepositoryFactory");
export const IPaymentHistoryRepositoryFactoryKey = Symbol("IPaymentHistoryRepositoryFactory");
export const IPaymentRepositoryFactoryKey = Symbol("IPaymentRepositoryFactory");
export const IPersonalInfoRepositoryFactoryKey = Symbol("IPersonalInfoRepositoryFactory");
export const IPlanRepositoryFactoryKey = Symbol("IPlanRepositoryFactory");
export const ITagRepositoryFactoryKey = Symbol("ITagRepositoryFactory");
export const ITalkRoomRepositoryFactoryKey = Symbol("ITalkRoomRepositoryFactory");
export const ILibraryRepositoryFactoryKey = Symbol("ILibraryRepositoryFactory");

export const ITalkRoomEditRepositoryFactoryKey = Symbol("ITalkRoomEditRepositoryFactory");
export const IUserRepositoryFactoryKey = Symbol("IUserRepositoryFactory");
export const IPushNotificationRepositoryFactoryKey = Symbol("IPushNotificationRepositoryFactory");
export const INotificationSettingRepositoryFactoryKey = Symbol("INotificationSettingRepositoryFactory");
export const IAdminToolRepositoryFactoryKey = Symbol("IAdminToolRepositoryFactory");
export const IConversionRepositoryFactoryKey = Symbol("IConversionRepositoryFactory");
export const IPlanPriceRepositoryFactoryKey = Symbol("IPlanPriceRepositoryFactory");
export const IMasterRepositoryFactoryKey = Symbol("IMasterRepositoryFactory");
export const IPlanPriceChangeReservationRepositoryFactoryKey = Symbol("IPlanPriceChangeReservationRepositoryFactory");
export const ICampaignRepositoryFactoryKey = Symbol("ICanpaignRepositoryFactory");
export const IPlanManageRepositoryFactoryKey = Symbol("IPlanManageRepositoryFactory");
export const IPlanPriceManageRepositoryFactoryKey = Symbol("IPlanPriceManageRepositoryFactory");

// inject される repository インスタンス生成用の factory を登録
// 環境変数等に応じて実装を切り替える
export const provideRepositories = (app: App) => {
  app.provide<() => IContentsRepository>(IContentsRepositoryFactoryKey, () => new ContentsRepository());
  app.provide<() => IContractMemberRepository>(
    IContractMemberRepositoryFactoryKey,
    () => new ContractMemberRepository(),
  );
  app.provide<() => IDeviceRepository>(IDeviceRepositoryFactoryKey, () => new DeviceRepository());
  app.provide<() => IDistributionRepository>(IDistributionRepositoryFactoryKey, () => new DistributionRepository());
  app.provide<() => ILineLinkRepository>(ILineLinkRepositoryFactoryKey, () => new LineLinkRepository());
  app.provide<() => ILineLinkStatusRepository>(
    ILineLinkStatusRepositoryFactoryKey,
    () => new LineLinkStatusRepository(),
  );
  app.provide<() => ILiveChatRepository>(ILiveChatRepositoryFactoryKey, () => new LiveChatRepository());
  app.provide<() => ILiveRepository>(ILiveRepositoryFactoryKey, () => new LiveRepository());
  app.provide<() => ILiveStatusRepository>(ILiveStatusRepositoryFactoryKey, () => new LiveStatusRepository());
  app.provide<() => ILoginRepository>(ILoginRepositoryFactoryKey, () => new LoginRepository());
  app.provide<() => IOgpRepository>(IOgpRepositoryFactoryKey, () => new OgpRepository());
  app.provide<() => IOwnerRepository>(IOwnerRepositoryFactoryKey, () => new OwnerRepository());
  app.provide<() => IPaymentHistoryRepository>(
    IPaymentHistoryRepositoryFactoryKey,
    () => new PaymentHistoryRepository(),
  );
  app.provide<() => IPaymentRepository>(IPaymentRepositoryFactoryKey, () => new PaymentRepository());
  app.provide<() => IPersonalInfoRepository>(IPersonalInfoRepositoryFactoryKey, () => new PersonalInfoRepository());
  app.provide<() => IPlanRepository>(IPlanRepositoryFactoryKey, () => new PlanRepository());
  app.provide<() => ITagRepository>(ITagRepositoryFactoryKey, () => new TagRepository());
  app.provide<() => ITalkRoomRepository>(ITalkRoomRepositoryFactoryKey, () => new TalkRoomRepository());

  app.provide<() => ILibraryRepository>(ILibraryRepositoryFactoryKey, () => new LibraryRepository());

  app.provide<() => ITalkRoomEditRepository>(ITalkRoomEditRepositoryFactoryKey, () => new TalkRoomEditRepository());
  app.provide<() => IUserRepository>(IUserRepositoryFactoryKey, () => new UserRepository());
  app.provide<() => IPushNotificationRepository>(
    IPushNotificationRepositoryFactoryKey,
    () => new PushNotificationRepository(),
  );
  app.provide<() => INotificationSettingRepository>(
    INotificationSettingRepositoryFactoryKey,
    () => new NotificationSettingRepository(),
  );
  app.provide<() => IAdminToolRepository>(IAdminToolRepositoryFactoryKey, () => new AdminToolRepository());
  app.provide<() => IConversionRepository>(IConversionRepositoryFactoryKey, () => new ConversionRepository());
  app.provide<() => IPlanPriceRepository>(IPlanPriceRepositoryFactoryKey, () => new PlanPriceRepository());

  app.provide<() => IMasterRepository>(IMasterRepositoryFactoryKey, () => new MasterRepository());

  app.provide<() => IPlanPriceChangeReservationRepository>(
    IPlanPriceChangeReservationRepositoryFactoryKey,
    () => new PlanPriceChangeReservationRepository(),
  );

  app.provide<() => ICampaignRepository>(ICampaignRepositoryFactoryKey, () => new CampaignRepository());

  app.provide<() => IPlanManageRepository>(IPlanManageRepositoryFactoryKey, () => new PlanManageRepository());

  app.provide<() => IPlanPriceManageRepository>(
    IPlanPriceManageRepositoryFactoryKey,
    () => new PlanPriceManageRepository(),
  );
};
