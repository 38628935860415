import snakecaseKeys from "snakecase-keys";
import { PlanCancelAnswer } from "~/entities/PlanCancelAnswer";
import { PlanCancelQuestion } from "~/entities/PlanCancelQuestion";
import { type IContractMemberRepository } from "~/interfaces/IContractMemberRepository";

export class ContractMemberRepository implements IContractMemberRepository {
  async contract(planId: string): Promise<void> {
    const res = await PostRequest<{ statusCode: number }>(`/contract_member/contract/${planId}`);
    if (!res || res.statusCode !== 200) {
      throw new Error("ContractMemberRepository.contract API Error");
    }
  }

  async contractWithoutPayment(sbPaymentId: string, planPriceId: string): Promise<void> {
    const res = await PostRequest<{ statusCode: number }>(
      `/contract_member/contract_without_payment/${sbPaymentId}/${planPriceId}`,
    );
    if (!res || res.statusCode !== 200) {
      throw new Error(`ContractMemberRepository.contractWithoutPayment API Error: ${JSON.stringify(res)}`);
    }
  }

  async contractWithoutPaymentUpdate(sbPaymentId: string, planPriceId: string): Promise<void> {
    const res = await PostRequest<{ statusCode: number }>(
      `/contract_member/contract_without_payment_update/${sbPaymentId}/${planPriceId}`,
    );
    if (!res || res.statusCode !== 200) {
      throw new Error(`ContractMemberRepository.contractWithoutPaymentUpdate API Error: ${JSON.stringify(res)}`);
    }
  }

  async cancel(planId: string): Promise<void> {
    const res = await PostRequest<{ statusCode: number }>(`/contract_member/cancel/${planId}`);
    if (!res || res.statusCode !== 200) {
      throw new Error("ContractMemberRepository.cancel API Error");
    }
  }

  async fetchQuestions(): Promise<PlanCancelQuestion[]> {
    const res = await GetRequest<{ statusCode: number; questionnaires: PlanCancelQuestion[] }>(
      `/contract_member/questionnaire`,
    );
    if (!res || res.statusCode !== 200) {
      throw new Error("ContractMemberRepository.fetchQuestions API Error");
    }
    return res.questionnaires;
  }

  async sendAnswers(planId: string, answers: PlanCancelAnswer[]): Promise<void> {
    const res = await PostRequest<{ statusCode: number }>(
      `/contract_member/questionnaire/${planId}`,
      JSON.stringify(snakecaseKeys({ answers }, { deep: true })),
    );
    if (!res || res.statusCode !== 200) {
      throw new Error("ContractMemberRepository.sendAnswers API Error");
    }
  }
}
